<div class="body-height">
  <div class="grad1">
    <section id="forgot-pwd" class="pt-3"  >
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <a href="/"><img src="assets/images/voce/logo.png" class="logo-login pt-4 pb-5"></a>
          </div>
        </div>
        <div class="login-box mobile-hide">
          <form (submit)="doResetPassword()" [formGroup]="form">
            <div class="row input-box">
              <div class="col page-description text-black text-center">
                <p>Please enter your new password below and press submit to change your password.</p>
              </div>
            </div>
            <div class="input-box">
              <div class="input-group input-group-lg mt-3"
                   [ngClass]="{ 'input-group-error': isFieldInvalid('password') }">
                <input type="password" class="form-control" name="password" formControlName="password" placeholder="Password"
                       aria-label="Username" aria-describedby="basic-addon1">
              </div>
              <div class="validation-error-message" *ngIf="isFieldInvalid('password') && form.controls.password.errors?.required">
                <span>Please enter a password</span>
              </div>
              <div class="validation-error-message" *ngIf="isFieldInvalid('password') && form.controls.password.errors?.rangeLength">
                <span>Please enter a password between 5 and 25 characters</span>
              </div>
              <div class="input-group input-group-lg mt-3"
                   [ngClass]="{ 'input-group-error': isFieldInvalid('confirmPassword') }">
                <input type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword"
                       placeholder="Confirm Password" aria-label="Username" aria-describedby="basic-addon1">
              </div>
              <div class="validation-error-message"
                   *ngIf="isFieldInvalid('confirmPassword') && form.controls.confirmPassword.errors?.required">
                <span>Please enter a password</span>
              </div>
              <div class="validation-error-message"
                   *ngIf="isFieldInvalid('confirmPassword') && form.controls.confirmPassword.errors?.equalTo">
                <span>Both passwords should match</span>
              </div>
            </div>
            <div class="row input-box">
              <div class="col text-center">
                <button type="submit" class="btn btn-primary btn-desktop bg-purple">Submit</button>
              </div>
            </div>
          </form>
        </div>
        <div class="login-box-mobile desktop-hide">
          <form (submit)="doResetPassword()" [formGroup]="form">
            <div class="row input-box">
              <div class="col page-description text-black text-center">
                <p>Please enter your new password below and press submit to change your password.</p>
              </div>
            </div>
            <div class="row input-box">
              <div class="col">
                <div class="input-group input-group-lg mt-3"
                     [ngClass]="{ 'input-group-error': isFieldInvalid('password') }">
                  <input type="password" class="form-control" name="password" formControlName="password" placeholder="Password"
                         aria-label="Username" aria-describedby="basic-addon1">
                </div>
                <div class="validation-error-message" *ngIf="isFieldInvalid('password') && form.controls.password.errors?.required">
                  <span>Please enter a password</span>
                </div>
                <div class="validation-error-message" *ngIf="isFieldInvalid('password') && form.controls.password.errors?.rangeLength">
                  <span>Please enter a password between 5 and 25 characters</span>
                </div>
                <div class="input-group input-group-lg mt-3"
                     [ngClass]="{ 'input-group-error': isFieldInvalid('confirmPassword') }">
                  <input type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword"
                         placeholder="Confirm Password" aria-label="Username" aria-describedby="basic-addon1">
                </div>
                <div class="validation-error-message"
                     *ngIf="isFieldInvalid('confirmPassword') && form.controls.confirmPassword.errors?.required">
                  <span>Please enter a password</span>
                </div>
                <div class="validation-error-message"
                     *ngIf="isFieldInvalid('confirmPassword') && form.controls.confirmPassword.errors?.equalTo">
                  <span>Both passwords should match</span>
                </div>
              </div>
            </div>
            <div class="row input-box">
              <div class="col text-center">
                <button type="submit" class="btn btn-primary btn-desktop bg-purple">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</div>


