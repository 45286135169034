import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '@api/state.service';


@Component({
  selector: 'oamw-content-library',
  templateUrl: './content-library.component.html',
  styleUrls: ['./content-library.component.scss']
})
export class ContentLibraryPage implements OnInit {

  isGoalSettings = true;
  isTreatmentDiscussions = false;
  isStayingOnTrack = false;
  isSupportNetwork = false;
  userId: string;

  constructor(private state: StateService,
    private router: Router,) { }

  ngOnInit(): void {
    // if(navigator.platform.includes('Mac')) {
    //   document.getElementById('contentLibrary').classList.add('mac-lib');
    // }
    !this.state.hasToken() && this.router.navigate(['/']);
    this.userId = this.state.user.id;
  }

  download(): void {}

  setGoalSettings() {
    this.isGoalSettings = true;
    this.isTreatmentDiscussions = false;
    this.isStayingOnTrack = false;
    this.isSupportNetwork = false;
  }

  setTreatment() {
    this.isGoalSettings = false;
    this.isTreatmentDiscussions = true;
    this.isStayingOnTrack = false;
    this.isSupportNetwork = false;
  }

  setStayingOnTrack() {
    this.isGoalSettings = false;
    this.isTreatmentDiscussions = false;
    this.isStayingOnTrack = true;
    this.isSupportNetwork = false;
  }

  setSupportNetwork() {
    this.isGoalSettings = false;
    this.isTreatmentDiscussions = false;
    this.isStayingOnTrack = false;
    this.isSupportNetwork = true;
  }
}
