import { Component, OnInit, AfterViewInit, HostListener, ViewChild, ElementRef, OnDestroy, NgZone, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { differenceInSeconds } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import {
  faPhone, faPhoneSlash, faMicrophone, faMicrophoneSlash,
  faVideo, faVideoSlash, faExclamationTriangle, faBan, faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { detect, BrowserInfo } from 'detect-browser';
import { Subject, Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import NoSleep from 'nosleep.js';

import { IsiStateService } from '@api/isi-state.service';
import { AppointmentsService } from '@api/appointments.service';
import { StateService } from '@api/state.service';
import { TwilioService } from '@api/twilio.service';
import { ProgramService } from '@api/program.service';
import { Program } from '@models/program';
import { LiveSessionService } from '@api/live-session.service';
import { AuthService } from '@api/auth.service';
import { ZoomService } from '@api/zoom.service';
import { participantLabel, captainLabel } from '@utils/constants';


@Component({
  selector: 'oamw-session-active',
  templateUrl: './session-active.component.html',
  styleUrls: ['./session-active.component.scss']
})
export class SessionActivePage implements OnInit, AfterViewInit, OnDestroy {

  mode: 'audio' | 'video';
  networkLevel: number;
  networkLevelRemote: number;
  networkLevelSub: Subscription;
  networkLevelStack = new Array<number>();

  isConnected = false;
  isAudioMuted = false;
  isVideoMuted = false;
  isAudioMutedRemote = true;
  isVideoMutedRemote = true;

  faPhone = faPhone;
  faPhoneSlash = faPhoneSlash;
  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;
  faVideo = faVideo;
  faVideoSlash = faVideoSlash;
  faExclamationTriangle = faExclamationTriangle;
  faBan = faBan;
  faCheckCircle = faCheckCircle;

  sessionStartTime: Date;
  sessionEndTime: Date;

  @ViewChild('localVideoContainer')
  localVideoContainer: ElementRef<HTMLDivElement>;
  @ViewChild('remoteVideoContainer')
  remoteVideoContainer: ElementRef<HTMLDivElement>;
  @ViewChild('localVideo')
  localVideo: ElementRef<HTMLVideoElement|HTMLCanvasElement>;
  @ViewChild('remoteVideo')
  remoteVideo: ElementRef<HTMLCanvasElement>;

  volume: number;
  height: number = window.innerHeight;

  sessionStarted = false;
  userType = 'PARTICIPANT';

  program: Program;
  sessionDuration = 30 * 60;
  sessionDurationLeft = 30 * 60;
  sessionGraceDuration = 15 * 60;
  timerDisplay: any;
  sessionEndMsg = 'Session will automatically end in ';
  isSessionActive = false;
  public isBrowserRefreshed: boolean;
  endModalVisible = false;
  browserInfo: BrowserInfo;
  isPCBranding = true;
  isScreenLockedSupported = false;

  userAgentStr = window.navigator.userAgent;
  uaHash = Array.from(this.userAgentStr)
                // tslint:disable-next-line:no-bitwise
                .reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0);
  telemetryTimer: Subscription;
  appointmentId: string;
  adrResults: any;
  participantLabel: string;
  captainLabel: string = "Care Guide";
  isBothPartyArrived: boolean;
  isCallEndedApiCalled = false;

  private readonly notifier: NotifierService;


  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    // this.twilioService.unpublishOnReload();
    console.log('User leaving page without completing session');
    this.zoomService.leaveSession(false);
    this.state.activeSessionDirty = false;
    //event.returnValue = true; /*!this.state.isSessionActive;*/
  }


  constructor(private router: Router,
              private ngZone: NgZone,
              private isiState: IsiStateService,
              private state: StateService,
              private appointmentsService: AppointmentsService,
              private twilioService: TwilioService,
              private zoomService: ZoomService,
              private programService: ProgramService,
              private liveDataSvc: LiveSessionService,
              private authService: AuthService,
              private notifierService: NotifierService) {
    this.notifier = notifierService;
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.url === '/upcoming-sessions') {}
    });
    this.participantLabel = participantLabel;
  }

  ngOnInit(): void {
    this.state.reloadRedirect = true;
    this.state.activeSessionDirty = true;
    
    if (navigator.platform.includes('Mac'))
      document.getElementById('activeSession').classList.add('mac-activeSession');

    if (!screen.orientation?.lock)
      this.isScreenLockedSupported = false;

    this.isiState.hideIsi();
    this.zoomService
        .sessionStarted
        .pipe(filter(status => status === true))
        .subscribe(_ => {
          this.sessionStarted = true;
          this.isSessionActive = true;
          this.state.isSessionActive = this.isSessionActive;
          this.sessionStartTime = utcToZonedTime(new Date().toISOString(), this.state.user.tzCanonicalName);
        });
    this.zoomService
        .sessionEnded
        .pipe(filter(status => status === true))
        .subscribe(_ => {
          this.sessionEndTime = new Date();
          // this.disconnectRoom();
        });
    this.zoomService
        .audioTrackStatus
        .subscribe(_ => this.isAudioMuted = !_);
    this.zoomService
        .audioTrackStatusRemote
        .subscribe(_ => this.isAudioMutedRemote = !_);
    this.zoomService
        .videoTrackStatus
        .subscribe(_ => this.isVideoMuted = !_);
    this.zoomService
        .videoTrackStatusRemote
        .subscribe(_ => this.isVideoMutedRemote = !_);
    this.zoomService
        .networkLevelRemote
        .subscribe(_ => this.networkLevelRemote = _);
    this.userType = this.state.user.type;
    this.volume = 50;

    this.getProgram();
    this.telemetryConnect();
    this.browserInfo = detect(navigator.userAgent) as BrowserInfo;

    this.telemetryPing();
    this.appointmentId = this.state.activeSession;

    // this.zoomService
    //     .adrResult
    //     .subscribe(adr => {
    //       if (adr.adrs)
    //         adr.adrs.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

    //       this.adrResults = adr;

    //       console.log('ADR Result: ', adr);
    //     });
    this.appointmentsService
        .getById(this.appointmentId)
        .subscribe(aptmt => {
          this.isBothPartyArrived = !!aptmt.participantArrival && !!aptmt.captainArrival;
        });

    this.isCallEndedApiCalled = false;
    this.authService.isDisconnectAndLoggedOut.subscribe( async status => {
      if (!!status && !this.isCallEndedApiCalled) {
        this.isCallEndedApiCalled = true;
        console.log("Disconnect~~~");
        await this.telemetrySender(true);
        this.telemetryTimer.unsubscribe();

        try {
          !!screen.orientation?.lock && screen.orientation?.unlock();
          document.exitFullscreen && document.exitFullscreen();
        } catch (e) {

        }
        this.endModalVisible = false;
        this.isSessionActive = false;
        this.state.clearIsSessionActive();
        await this.zoomService.disposeLocalMedia();

        this.authService.logout();
        location.href = '/';
      }
    });
  }

  async ngAfterViewInit() {
    let isValidInit = true;
    if (!this.state.hasToken()) {
      //await this.router.navigate(['/']);
      isValidInit = false;
      location.href = '/';
    }

    if (this.state.activeSession === '' || this.state.activeSession === undefined){
      //await this.router.navigate([this.state.user.type === 'CAPTAIN' ? '/upcoming-sessions-faculty' : '/upcoming-sessions']);
      isValidInit = false;
      location.href = this.state.user.type === 'CAPTAIN' ? '/upcoming-sessions-faculty' : '/upcoming-sessions';
    }
    else if (this.state.twilioToken === '' || this.state.twilioToken === undefined){
      // await this.router.navigate(['/start-session']);
      isValidInit = false;
      location.href = '/start-session';
    }
    if (isValidInit)
      await this.connectRoom();

    const noSleep = new NoSleep();
    document.addEventListener('click', function enableNoSleep() {
      document.removeEventListener('click', enableNoSleep, false);
      noSleep.enable();
    }, false);
  }

  async ngOnDestroy() {
    try {
      document.exitFullscreen && await document.exitFullscreen();
    } catch (e) {}

    // await this.zoomService.disposeLocalMedia();
    this.liveDataSvc.disconnect();
    this.telemetryTimer.unsubscribe();
    this.appointmentsService
        .updateDuration(this.appointmentId)
        .subscribe(e => console.log(e));
    this.state.clearIsAudioMuted();
    this.state.clearIsVideoMuted();
    this.disconnectRoom(false);
  }

  canDeactivate() {
    return !this.state.isSessionActive;
  }


  getProgram() {
    this.programService.getProgram()
                       .subscribe(p => {
                         this.program = p[0];
                         this.sessionDuration = this.program.sessionDuration * 60;
                         this.startTimer();
                        });
  }

  startTimer() {
    timer(0, 1000).subscribe(_ => {
      zonedTimeToUtc(this.state.sessionStartTime, 'UTC');
      const a = zonedTimeToUtc(this.state.sessionStartTime, this.state.user.tzCanonicalName, { timeZone: 'UTC' });
      const b = new Date(new Date().toISOString());
      const diff = differenceInSeconds(b, a);
      this.sessionDurationLeft = this.sessionDuration - diff;
      if (this.sessionDurationLeft < 1) {
        // this.sessionGraceDuration--;
        this.timerDisplay = this.getDisplayTimer(this.sessionDurationLeft + 900);
        if (this.sessionDurationLeft === -900)
          this.disconnectRoom(true);
      } else
        // this.sessionDuration--;
        this.timerDisplay = this.getDisplayTimer(this.sessionDurationLeft);
    });
  }

  async disconnectRoom(isEndSessionReq?: boolean) {
    try {
      !!screen.orientation?.lock && screen.orientation?.unlock();
      document.exitFullscreen && document.exitFullscreen();
    } catch (e) {

    }

    this.telemetrySender(!!isEndSessionReq);
    this.endModalVisible = false;
    this.isSessionActive = false;
    this.state.clearIsSessionActive();
    this.zoomService.disposeLocalMedia();

    console.log('Going to Disconnecting Room: ', isEndSessionReq);
    if (!isEndSessionReq) {
      console.log('Disconnecting Room: ', isEndSessionReq);
      this.zoomService.leaveSession(false);
      return;
    };

    this.zoomService.leaveSession(this.state.user.type === 'CAPTAIN');
    if (this.state.user.type === 'CAPTAIN')
      this.endSession();
    else {
      this.state.clearTwilioToken();
      this.state.clearSessionStartTime();
      //this.router.navigate(['/rating']);
      location.href = '/rating';
    }
  }

  showEndModal() {
    this.endModalVisible = true;
  }


  endSession() {

    if (this.sessionEndTime === undefined)
      this.sessionEndTime = utcToZonedTime(new Date().toISOString(), this.state.user.tzCanonicalName);

    if (this.sessionStartTime === undefined)
      this.sessionStartTime = new Date(this.state.sessionStartTime);

    const data = {
      appointmentId: this.state.activeSession,
      duration: differenceInSeconds(this.sessionEndTime, this.sessionStartTime),
      externalId: 'externalId'
    };
    if (data.duration < 0)
      data.duration = 0;

    this.appointmentsService
        .completedAppointment(data)
        .subscribe(success => {
          if (success) {
            // this.router.navigate(['/upcoming-sessions-faculty']);
            this.state.clearActiveSession();
            this.state.clearTwilioToken();
            this.state.clearSessionStartTime();
            location.href = '/upcoming-sessions-faculty';
          }
        });
  }

  remindCandidate() {
    const data = {
      appointmentId: this.state.activeSession
    };
    this.appointmentsService
        .overdueReminder(data)
        .subscribe(success => {
          success && this.notifier.notify('error', 'Reminder sent to ' + participantLabel + '!');
        });
  }

  setVolume() {
    // this.remoteVideo.nativeElement.classList.add('hidden');
    const vid = this.remoteVideo.nativeElement.querySelector('audio');
    vid.volume = this.volume / 100;
  }

  toggleAudio() {
    this.zoomService.toggleAudio();
    this.state.isAudioMuted = this.isAudioMuted;
    // this.isAudioMuted = !this.isAudioMuted;
  }

  async toggleVideo() {
    await this.zoomService.toggleVideo();
    this.state.isVideoMuted = this.isVideoMuted;
    // this.isVideoMuted = !this.isVideoMuted;
  }

  brandingPC() {
    this.isPCBranding = false;
    timer(5000).subscribe(_ => {
      this.isPCBranding = true;
    });
  }

  ///

  private async connectRoom() {
    await this.ngZone.runOutsideAngular(async () => {
      await this.zoomService.joinSession();
      await this.zoomService.initLocalMedia(this.localVideoContainer, this.localVideo);
      await this.zoomService.setupRemoteMedia(this.remoteVideoContainer, this.remoteVideo);
      this.networkLevelSub = this.zoomService.networkLevel.subscribe(level => {
        this.networkLevelStack.push(level);
        console.log('Networks: ' + this.networkLevelStack);
        this.networkLevelStack.length > 2 && this.networkLevelStack.shift();
        const average = this.networkLevelStack.reduce((prev, curr) => prev + curr) / this.networkLevelStack.length;
        console.log('Avg: ' + average);
        this.networkLevel = average;
      });
      this.zoomService.setIsConnected(true);
      this.isConnected = true;
    });
  }

  getDisplayTimer(time: number) {
    const hours = '0' + Math.floor(time / 3600);
    const minutes = '0' + Math.floor(time % 3600 / 60);
    const seconds = '0' + Math.floor(time % 3600 % 60);

    return hours.slice(-2, -1) + '' + hours.slice(-1) + ':' +
    minutes.slice(-2, -1) + '' + minutes.slice(-1) + ':' +
    seconds.slice(-2, -1) + '' + seconds.slice(-1);
  }

  onResize(event) {
    this.height = event.target.innerHeight;
  }

  telemetryConnect() {
    this.liveDataSvc
        .connect()
        .subscribe(data => {
          if (data.msg === 'sessionEndRequest' && this.state.user.type === 'PARTICIPANT')
            this.disconnectRoom(true);
          else if (data.msg === 'sessionEndRequest' && this.state.user.type === 'CAPTAIN')
            this.notifier.notify('error', 'Session has ended!');

          const diff = differenceInSeconds(new Date(), new Date(this.state.sessionStartTime));

          if (data.msg === 'disconnect' && diff > this.sessionDuration)
            this.disconnectRoom(false);

          if (data.msg === 'initialized')
            this.telemetrySenderAgent(false);

          if (data.msg === 'kickOut') {
            this.zoomService.disposeLocalMedia();
            this.zoomService.leaveSession(false);
            this.state.isSessionActive = false;
            this.authService.logout();
            // this.router.navigate(['/']);
            location.href = '/';
            
          }
        });
  }

  telemetryPing() {
    const pingData = {
      userId: this.state.user.id,
      appointmentId: this.state.activeSession,
      uaHash: this.uaHash.toString(),
      userAgent: this.userAgentStr
    };
    this.liveDataSvc.sendPing(pingData);
  }

  telemetrySenderAgent(isEndReq) {
    this.telemetryTimer =  timer(0, 1000).subscribe(_ => {
      this.isConnected && this.telemetrySender(isEndReq);
    });
  }

  async telemetrySender(isEndReq) {
    if (!this.state?.user?.id) return;

    const teleData = {
      userId: this.state.user.id,
      appointmentId: this.state.activeSession,
      networkLevelLocal: this.networkLevel,
      networkLevelRemote: this.networkLevelRemote,
      videoStatusLocal: !this.isVideoMuted,
      videoStatusRemote: !this.isVideoMutedRemote,
      audioStatusLocal: !this.isAudioMuted,
      audioStatusRemote: !this.isAudioMutedRemote,
      isActive: true,
      isEndRequest: isEndReq,
      uaHash: this.uaHash.toString()
    };
    this.liveDataSvc.send(teleData);
  }

}
