<div class="pt-10p">
  <div class="video-box">
    <div class="body-height">
      <section id="rating" class="pt-3 bg-grey">
        <div class="container">
          <div class="row">
            <div class="col purple mb-4">
              <h1 class="width-70 m-width-100">How helpful was this session in making you feel supported as a care partner?</h1>
              <p class="note">1 Star = Not helpful,<br> 5 Stars = Very helpful</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="rating-stars mb-5 ml-1">
                <rating [max]="5" [customTemplate]="rt" [(ngModel)]="rating" class="row"></rating>
                <ng-template #rt let-idx="index" let-val="value">
                  <img [src]="'assets/images/Star' + (idx < val ? '_selected' : '') + '.png'" class="col">
                </ng-template>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <button type="button" class="btn btn-secondary-outline my-4" (click)="rate()"
                [disabled]="rating < 1">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
