<div class="column">
  <section id="upcomingSessionsUser" class="bg-grey"
    [ngClass]="sessions != undefined && sessions.length > 0 ? 'upcoming-list' : ''">
    <div class="container">
      <div class="row" *ngIf="!isDisplayed">
        <div class="col text-center">
          <h2>Loading...</h2>
        </div>
      </div>
      <div *ngIf="isDisplayed">
        <div *ngIf="sessions != undefined && sessions.length > 0">
          <div class="row">
            <div class="col m-text-center">
              <button type="button" class="btn btn-secondary" *ngIf="sessionsLeft > 0"
                [routerLink]="['/select-topic']"><span class="ico">+</span><u class="no-text-decoration">Schedule New
                  Session</u></button>
              <button type="button" class="btn btn-secondary" disabled *ngIf="sessionsLeft < 1"
                [routerLink]="['/select-topic']"><span class="ico">+</span><u class="no-text-decoration">Schedule New
                  Session</u></button>
              <div class="validation-error-message" *ngIf="sessionsLeft < 1">
                <span>You have booked maximum allowed session.</span>
              </div>
            </div>
          </div>
          <div class="row" *ngFor="let sess of sessions">
            <div class="col">
              <div class="card-session">
                <div class="row mobile-sessions">
                  <div class="col">
                    <h2><b>Date:</b> {{ sess.date }} {{ sess.time }} {{ sess.zoneAbbr }}</h2>
                    <h2><b>Topic:</b> {{ sess.topic }}</h2>
                    <h2><b>Care Guide:</b> {{ sess.captainFirstName }} {{ sess.captainLastName }}</h2>
                  </div>
                  <div class="col-3 btn-container text-right p-0">
                    <div class="button-text">
                      <img src="assets/images/JoinSession-d.png" (click)="joinSession(sess.id, sess.ts)"/>
                    </div>
                    <div class="button">
                      <img src="assets/images/Delete-d.png" (click)="showDeleteModal(sess.id)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isDisplayNot && (sessions==undefined || sessions.length < 1)">
          <div class="col no-session-text">
            No Upcoming Sessions
          </div>
          <div class="row pt-2 upcoming-btn">
            <div class="col text-center">
              <a type="button" class="btn btn-primary mb-5" *ngIf="sessionsLeft > 0"
                [routerLink]="['/select-topic']">Schedule New Session</a>
              <a type="button" class="btn btn-primary mb-5 disabled" *ngIf="sessionsLeft < 1">Schedule New
                Session</a>
              <div class="validation-error-message" *ngIf="sessionsLeft < 1">
                <span>You have booked maximum allowed session.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Terms Modal -->
    <div id="confirm-popup" *ngIf="deleteModalVisible">
      <div class="confirm-body">
        <div>
          <div>
            <div class="col text-center">
              <p>Are you sure you want to cancel your appointment?</p>
            </div>
          </div>
        </div>

        <div class="confirm-tray">
          <div class="row">
            <div class="col width-30 text-right m-width-100 m-text-center">
              <button type="button" class="btn btn-primary outline-btn bg-light" (click)="deleteModalVisible = false">No</button>
            </div>
            <div class="col m-text-center">
              <button type="button" class="btn btn-primary" (click)="deleteSession()">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
