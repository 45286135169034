import { Injectable } from '@angular/core';
import * as store from 'store';

import { User } from '@models/user';
import { SessionType } from '@models/appointment';
import { TimeZone } from '@models/time-zone';


@Injectable({
  providedIn: 'root'
})
export class StateService {

  private ACCESS_TOKEN = 'access_token';
  private ACCESS_TOKEN_EXPIRY = 'access_token_expiry';
  private SELECTED_TOPIC_PARTICIPANT = 'selected_topic_participant';
  private SELECTED_CAPTAIN_PARTICIPANT = 'selected_captain_participant';
  private USER = 'user';
  private ACTIVE_SESSION = 'active_session';
  private RELOAD_REDIRECT = 'is_active_sesion_page_reload';
  private SESSION_TYPE = 'session_type';
  private TWILIO_TOKEN = 'twilio_token';
  private SESSION_START_TIME = 'session_start_time';
  private IS_SESSION_ACTIVE = 'is_session_active';
  private IS_AUDIO_MUTED = 'is_audio_muted';
  private IS_VIDEO_MUTED = 'is_video_muted';
  private TIME_ZONES = 'time_zones';
  private ACTIVE_SESSION_DIRTY = 'is_active_session_page_dirty';


  constructor() { }

  get timeZones() {
    return store.get(this.TIME_ZONES) as TimeZone[];
  }

  set timeZones(timeZones: any) {
    store.set(this.TIME_ZONES, timeZones);
  }

  //#region TOKEN
  get token() {
    return store.get(this.ACCESS_TOKEN) as string;
  }

  set token(tokenId: string) {
    store.set(this.ACCESS_TOKEN, tokenId);
  }

  hasToken() {
    return !!this.token;
  }

  clearToken() {
    store.remove(this.ACCESS_TOKEN);
  }
  //#endregion

  //#region TOKEN EXPIRY
  get tokenExpiry() {
    return store.get(this.ACCESS_TOKEN_EXPIRY) as Date;
  }

  set tokenExpiry(expiryDate: Date) {
    store.set(this.ACCESS_TOKEN_EXPIRY, expiryDate);
  }

  isTokenExpired() {
    return this.tokenExpiry <= new Date();
  }

  clearTokenExpiry() {
    store.remove(this.ACCESS_TOKEN_EXPIRY);
  }
  //#endregion


  //#region PARTICIPANT
  set selectedTopicByParticipant(topic: string) {
    store.set(this.SELECTED_TOPIC_PARTICIPANT, topic);
  }

  get selectedTopicByParticipant() {
    return store.get(this.SELECTED_TOPIC_PARTICIPANT);
  }

  clearTopicByParticipant() {
    store.remove(this.SELECTED_TOPIC_PARTICIPANT);
  }

  set selectedCaptainByParticipant(captain: string) {
    store.set(this.SELECTED_CAPTAIN_PARTICIPANT, captain);
  }

  get selectedCaptainByParticipant() {
    return store.get(this.SELECTED_CAPTAIN_PARTICIPANT);
  }

  clearCaptainByParticipant() {
    store.remove(this.SELECTED_CAPTAIN_PARTICIPANT);
  }
  //#endregion


  //#region USER
  get user() {
    return store.get(this.USER) as User;
  }

  set user(user: User) {
    store.set(this.USER, user);
  }

  hasUserDetails() {
    return this.user || { id: '', firstName: '', lastName: '', timezone: '', title: '', type: 'PARTICIPANT' };
  }

  clearUser() {
    store.remove(this.USER);
  }
  //#endregion


  //#region SESSION
  get activeSessionDirty() {
    return store.get(this.ACTIVE_SESSION_DIRTY) as boolean;
  }

  set activeSessionDirty(state: boolean) {
    store.set(this.ACTIVE_SESSION_DIRTY, state);
  }

  get reloadRedirect() {
    return store.get(this.RELOAD_REDIRECT) as boolean;
  }

  set reloadRedirect(state: boolean) {
    store.set(this.RELOAD_REDIRECT, state);
  }

  get activeSession() {
    return store.get(this.ACTIVE_SESSION) as string;
  }

  set activeSession(sessionId: string) {
    store.set(this.ACTIVE_SESSION, sessionId);
  }

  clearActiveSession() {
    store.remove(this.ACTIVE_SESSION);
  }

  get sessionType() {
    return store.get(this.SESSION_TYPE) as SessionType;
  }

  set sessionType(type: SessionType) {
    store.set(this.SESSION_TYPE, type);
  }

  clearSessionType() {
    store.remove(this.SESSION_TYPE);
  }

  get twilioToken() {
    return store.get(this.TWILIO_TOKEN) as string;
  }

  set twilioToken(token: string) {
    store.set(this.TWILIO_TOKEN, token);
  }

  clearTwilioToken() {
    store.remove(this.TWILIO_TOKEN);
  }

  get sessionStartTime() {
    return store.get(this.SESSION_START_TIME) as string;
  }

  set sessionStartTime(date: string) {
    store.set(this.SESSION_START_TIME, date);
  }

  clearSessionStartTime() {
    store.remove(this.SESSION_START_TIME);
  }


  get isSessionActive() {
    return store.get(this.IS_SESSION_ACTIVE) as boolean;
  }

  set isSessionActive(isActive: boolean) {
    store.set(this.IS_SESSION_ACTIVE, isActive);
  }

  clearIsSessionActive() {
    store.remove(this.IS_SESSION_ACTIVE);
  }
  //#endregion

  //#region AUDIO & VIDEO
  get isAudioMuted() {
    return store.get(this.IS_AUDIO_MUTED) as boolean;
  }

  set isAudioMuted(muted: boolean) {
    store.set(this.IS_AUDIO_MUTED, muted);
  }

  clearIsAudioMuted() {
    store.remove(this.IS_AUDIO_MUTED);
  }

  get isVideoMuted() {
    return store.get(this.IS_VIDEO_MUTED) as boolean;
  }

  set isVideoMuted(muted: boolean) {
    store.set(this.IS_VIDEO_MUTED, muted);
  }

  clearIsVideoMuted() {
    store.remove(this.IS_VIDEO_MUTED);
  }
  //#endregion

  clearAll() {
    this.clearToken();
    this.clearTokenExpiry();
    this.clearTopicByParticipant();
    this.clearCaptainByParticipant();
    this.clearUser();
    this.clearActiveSession();
    this.clearSessionType();
    this.clearTwilioToken();
    this.clearSessionStartTime();
    this.clearIsSessionActive();
    this.clearIsAudioMuted();
    this.clearIsAudioMuted();
  }

}
