import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, timer } from 'rxjs';
import { shareReplay, map, catchError, distinctUntilChanged, timeout } from 'rxjs/operators';
import { addSeconds } from 'date-fns';

import { environment } from '../../environments/environment';

import { StateService } from './state.service';
import { TimeZoneService } from './time-zone.service';
import { User } from '@models/user';


@Injectable({ providedIn: 'root' })
export class AuthService {

  private isLoggedIn$: BehaviorSubject<boolean>;
  private isDisconnectAndLoggedOut$: BehaviorSubject<boolean>;
  private user$: BehaviorSubject<User>;


  constructor(private http: HttpClient, private state: StateService, private timeZoneService: TimeZoneService) {
    this.isLoggedIn$ = new BehaviorSubject<boolean>(state.hasToken() && !state.isTokenExpired());
    this.isDisconnectAndLoggedOut$ = new BehaviorSubject<boolean>(false);
    this.user$ = new BehaviorSubject<User>(state.hasUserDetails());
   }

  get isLoggedIn() {
    return this.isLoggedIn$
               .asObservable()
               .pipe(shareReplay(1));
  }

  get isDisconnectAndLoggedOut() {
    return this.isDisconnectAndLoggedOut$
               .asObservable()
               .pipe(shareReplay(1));
  }

  setDisconnectAndLoggedOut(state: boolean) {
    this.isDisconnectAndLoggedOut$.next(state);
  }

  get user() {
    return this.user$
               .asObservable()
               .pipe(shareReplay(1));
  }

  login(username: string, password: string) {
    const loginPayload = {
                           username,
                           password,
                           grant_type: 'password',
                           client_id: 'oauth-admin-client',
                           client_secret: 'oauth-admin-password'
                         };

    return this.http
               .post<any>(environment.apiUrl + '/oauth/token',
                          this.transformReqest(loginPayload),
                          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
               .pipe(
                 distinctUntilChanged(),
                 map((data: { access_token: string, expires_in: number }) => {
                   let currTime = new Date();
                   console.log('Logged in!');
                   this.state.token = data.access_token;
                   this.state.tokenExpiry = addSeconds(currTime, data.expires_in);
                   this.isLoggedIn$.next(true);

                   return true;
                 }),
                 catchError(this.handleError())
               );
  }

  silentLogin(username: string, password: string) {
    const loginPayload = {username,
                          password,
                          grant_type: 'password',
                          client_id: 'oauth-admin-client',
                          client_secret: 'oauth-admin-password'};

    return this.http
               .post<any>(environment.apiUrl + '/oauth/token',
                          this.transformReqest(loginPayload),
                          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
               .pipe(
                 distinctUntilChanged(),
                 map((data: {access_token: string}) => {
                   console.log('Silent Logged in!');
                   this.state.token = data.access_token;

                   return true;
                 }),
                 catchError(this.handleError())
               );
  }

  directLogin(dlid: string) {

    return this.http
               .post<any>(environment.apiUrl + '/directLogin?dlid=' + dlid, {})
               .pipe(
                 distinctUntilChanged(),
                 map((data: {access_token: string}) => {
                   console.log('Direct Logged in!');
                   this.state.token = data.access_token;

                   return true;
                 }),
                 catchError(this.handleError())
               );
  }

  getUserDetails() {
    return this.http
               .get<User>(environment.apiUrl + '/user/me')
               .pipe(
                 distinctUntilChanged(),
                 map(data => {
                  const user: User = {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    timeZoneId: data.timeZoneId,
                    type: data.type,
                    appointmentsCount: data.appointmentsCount
                  };
                  this.user$.next(user);
                  this.state.user = user;
                  if (!!user.timeZoneId) {
                    let t = this.state.timeZones.find(t => t.id === user.timeZoneId);
                    user.tzCanonicalName = t.tzCanonicalName;
                    user.abbr = t.abbr;
                    this.user$.next(user);
                    this.state.user = user;
                  }
                  return data ;
                 }),
                 catchError(this.handleError())
               );
  }

  updateLoginFlag() {
    this.isLoggedIn$.next(this.state.hasToken());
  }

  logout() {
    this.state.clearAll();
    this.isLoggedIn$.next(false);
  }

  ///

  private handleError() {
    return (err: any) => {
      console.error('AUTH ERROR:', err);

      this.state.clearToken();
      this.isLoggedIn$.next(false);

      return of(false);
    };
  }

  private transformReqest(obj) {
    const str = [];
    for (const p in obj){
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
    return str.join('&');
    }
}
