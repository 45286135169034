import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@api/auth.service';
import { User } from "@models/user";
import { StateService } from '@api/state.service';


@Component({
  selector: 'ke-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginPage implements OnInit {

  form: FormGroup;
  showError = false;

  submitted = false;
  user: User;

  constructor(private fb: FormBuilder,
              private auth: AuthService,
              private state: StateService,
              private router: Router) { }

  ngOnInit(): void {
    // if(navigator.platform.match('Mac') !== null) {
    //   document.getElementById('login').classList.add('mac-login');
    // }

    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });

    this.auth.user.subscribe(u => this.user = u );
    this.state.hasToken() && this.router.navigate(['/upcoming-sessions' + (this.user.type === 'CAPTAIN' ? '-faculty' : '')]);

    this.auth.setDisconnectAndLoggedOut(false);
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return !control.valid && (control.touched || (control.untouched && this.submitted));
  }

  doLogin() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    this.auth
    .login(this.form.value.email.toLowerCase(), this.form.value.password)
    .subscribe(success => {
      console.log('Logged in Status: ' + success);
      success && this.getMe();

      this.showError = !success;
    });
  }

  getMe() {
    this.auth
    .getUserDetails()
    .subscribe(success => {
      success &&
        this.router.navigate(['/upcoming-sessions' + (this.user.type === 'CAPTAIN' ? '-faculty' : '')]);
    });
  }
}
