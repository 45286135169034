import { Component, OnInit } from '@angular/core';
import { AccountsService } from '@api/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'oamw-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribePage implements OnInit {

  unsubscribed = false;
  userId: string;


  constructor(private route: ActivatedRoute,
    private router: Router,
              private accountsService: AccountsService) { }

  ngOnInit(): void {
    // if (navigator.platform.includes('Mac')) {
    //   document.getElementById('unsubscribe').classList.add('mac-unsub');
    // }
    this.route.queryParams.subscribe(params => {
      this.userId = params.token;
    });
  }

  unsubscribe() {
    this.accountsService.unsubscribe({userId: this.userId})
        .subscribe(success => {
          this.unsubscribed = success;
        });
  }
}
