<div>
    <section id="landing" class="mt-auto">
        <div class="container">
            <div class="row m-align-center header-container m-hide">
                <div class="ol width-70">
                    <div class="row m-hide">
                        <div class="col landing-logo-div">
                          <img src="assets/images/voce/logo.png" class="landing-logo">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <h2 class="landing-page-title">Welcome to <b>Connected By Hope</b>, an easy way for you to connect with other care partners of adults living with schizophrenia</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <button type="text" [routerLink]="['/signup']" class="btn btn-primary btn-desktop bg-purple">Sign Up</button>
                        </div>
                    </div>
                </div>
                <div class="width-30 landing-hero-align">
                  <div class="row">
                    <div class="col-sm login-button">
                      <button type="text" [routerLink]="['/login']" class="btn btn-secondary btn-desktop bg-purple">Login</button>
                    </div>
                  </div>
                  <img src="assets/images/voce/LandingPage_IMG.png" class="landing-hero-img">
                </div>
            </div>
            <div class="row m-align-center section-2 m-hide">
              <div class="col-sm sub-section">
                <h3>What Is <b>Connected By Hope?</b></h3>
                <div class="flex">
                  <div class="width-60">
                    <p>
                      It’s an online video chat platform that brings together those caring for adults living with schizophrenia, allowing them to learn about the personal experiences of someone further along the care partner journey.
                    </p>
                    <p>
                      Get support and insights from those who truly understand your challenges.
                    </p>
                  </div>
                  <div class="width-40 text-right">
                    <img src="assets/images/voce/phone-d.png" class="phone-img">
                  </div>
                </div>
              </div>
              <div class="col-sm sub-section">
                <h3>Caring for Yourself and Your Loved One</h3>
                <div class="flex">
                  <div class="width-50">
                    <p>
                      Signing up for <b class="purple">Connected By Hope</b> will also give you access to tools and resources that may help you have important discussions with your loved one living with schizophrenia.
                    </p>
                  </div>
                  <div class="width-50 text-right">
                    <img src="assets/images/voce/clipboard-d.png" class="clipboard-img">
                  </div>
                </div>
              </div>
            </div>
            <div class="d-hide">
              <div class="row header-container">
                <div class="width-30">
                  <img src="assets/images/voce/logo-m.png" class="landing-logo">
                </div>
                <div class=" width-60 text-right">
                  <button type="text" [routerLink]="['/login']" class="btn btn-secondary btn-desktop bg-purple">Login</button>
                </div>
              </div>
              <img src="assets/images/voce/HeaderIMG-m.png" class="landing-hero-img">
              <div class="row">
                <div class="col-sm">
                  <h2 class="landing-page-title text-m">Welcome to <b>Connected By Hope</b>, an easy way for you to connect with other care partners of adults living with schizophrenia</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-sm text-center sign-up-btn">
                  <button type="text" [routerLink]="['/signup']" class="btn btn-primary btn-desktop bg-purple">Sign Up</button>
                </div>
              </div>
              <div class="row m-align-center section-2 d-hide">
                <div class="col-sm sub-section">
                  <h3>What Is <b>Connected By Hope?</b></h3>
                  <div class="flex">
                    <div class="width-60">
                      <p>
                        It’s an online video chat platform that brings together those caring for adults living with schizophrenia, allowing them to learn about the personal experiences of someone further along the care partner journey.
                      </p>
                    </div>
                    <div class="width-30 text-right">
                      <img src="assets/images/voce/phone-d.png" class="phone-img">
                    </div>
                  </div>
                  <div>
                    <p class="m-0">
                      Get support and insights from those who truly understand your challenges.
                    </p>
                  </div>
                </div>
                <div class="col-sm sub-section">
                  <h3>Caring for Yourself and<br/>Your Loved One</h3>
                  <div class="text-center">
                    <img src="assets/images/voce/clipboard-d.png" class="clipboard-img">
                  </div>
                  <div>
                    <p class="m-0">
                      Signing up for <b class="purple">Connected By Hope</b> will also give you access to tools and resources that may help you have important discussions with your loved one living with schizophrenia.
                    </p>
                  </div>

                </div>
              </div>
            </div>
            <div class="row section-3">
                <div class="landing-head">
                    <h2 class="landing-page-title">Ready to Join <br class="d-hide"/><b>Connected By Hope</b>?</h2>
                </div>
              <div class="col-sm text-center">
                <button type="text" [routerLink]="['/signup']" class="btn btn-primary btn-desktop bg-purple">Sign Up</button>
              </div>
            </div>
            <div class="divider-container">
              <div class="divider">
                &nbsp;
              </div>
            </div>
            <div class="footer">
              <div class="row">
                <div class="width-30 m-width-100">
                  <img src="assets/images/voce/JJlogo-d.png" class="jj-logo">
                </div>
                <div class="width-64 m-width-100">
                  <p class="footer-text">This site is published by Janssen Pharmaceuticals, Inc., which is solely responsible for its contents. The material on this
                    site is intended only as informational or as an educational aid and it is not intended to be taken as medical advice. The
                    ultimate responsibility for patient care resides with a healthcare professional.</p>
                  <p class="footer-text">This information is intended for the use of patients and caregivers in the United States and Puerto Rico only. Laws,
                    regulatory requirements, and medical practices for pharmaceutical products vary from country to country. The Prescribing
                    Information included here may not be appropriate for use outside the United States and Puerto Rico.</p>
                  <p class="footer-text">Third party trademarks used herein are trademarks of their respective owners.</p>
                  <p class="footer-text">
                    Last updated September 2024<span class="spacer-padding">|</span>cp-473097v1
                  </p>
                  <p class="footer-text">
                    <a href="https://www.janssen.com/us/contact-us" class="f-link">Contact Us</a>
                    <a href="https://www.hopeforschizophrenia.com/sitemap/" class="f-link" >Site Map</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="divider-container">
              <div class="f-divider">
                &nbsp;
              </div>
            </div>
            <div class="footer m-pt-6">
              <div class="row m-column-reverse">
                <div class="width-30 align-self-end m-width-100">
                  <p class="d-hide m-0" style="font-size: 3vw">&nbsp;</p>
                  <p class="footer-text">&copy; Johnson & Johnson and its affiliates 2024</p>
                </div>
                <div class="width-64 m-width-100">
                  <p class="footer-text">
                    <a href="https://innovativemedicine.jnj.com/us/privacy-policy " class="f-link text-decoration-none">Privacy Policy</a>
                    <a href="https://www.janssen.com/us/legal-notice" class="f-link text-decoration-none">Legal Notice</a>
                    <a href="https://www.hopeforschizophrenia.com/cookie-policy/" class="f-link text-decoration-none">Cookie Policy</a><br class="d-hide">
                    <a href="https://www.hopeforschizophrenia.com/cookie-policy/" class="f-link text-decoration-none">Customize Cookie Settings</a>
                  </p>
                  <p class="footer-text">
                    <a href="https://privacyportal-cdn.onetrust.com/dsarwebform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/ac3f202f-da7f-436a-b609-7f2364c326b6.html?WebsiteName=connectedbyhope.com" class="f-link text-decoration-none"><span>Do not sell or share my personal information</span></a>
                    <a href="https://privacyportal-cdn.onetrust.com/dsarwebform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/ac3f202f-da7f-436a-b609-7f2364c326b6.html?WebsiteName=connectedbyhope.com" class="f-link text-decoration-none"><span class="spacer-padding">Limit the use of my sensitive personal information</span></a>
                  </p>
                </div>
              </div>
            </div>
        </div>
    </section>
  </div>
