import { Component, ViewChild, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Router } from '@angular/router';

import { enGbLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerInlineDirective } from 'ngx-bootstrap/datepicker';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';

import { format, toDate, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { NotifierService } from 'angular-notifier';

import { Error } from '@utils/handleError';

import { Program } from '@models/program';
import { AvailabilityData, Availability } from '@models/captain';
import { ProgramService } from '@api/program.service';
import { AvailabilitiesService } from '@api/availabilities.service';
import { StateService } from '@api/state.service';
import { CalendarEvent, CalendarView } from 'angular-calendar';


@Component({
  selector: 'omw-set-availability',
  templateUrl: './set-availability.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./set-availability.component.scss']
})
export class SetAvailabilityPage implements OnInit {


  @ViewChild(BsDatepickerInlineDirective, { static: false })
  datePicker: BsDatepickerInlineDirective;

  refresh: Subject<any> = new Subject();
  selectedDates = [];
  prevDates = [];
  dateCustomClasses;
  toDdelete: boolean = false;
  view: string = 'month';

  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  showAll = false;

  colors: any = {
    green: {
      primary: '#a8d29f',
      secondary: '#a8d29f',
    }
  };


  disabledButton: boolean = false;

  datepickerConfig = {
    showWeekNumbers: false,
    minDate: new Date(),
    displayOneMonthRange: true
  };



  timeSectionVisible = false;
  availablility: { stime: string, etime: string }[] = [];
  timings: Array<string> = [];
  program: Program;
  availabilityData: AvailabilityData;
  errorMessage = '';
  availableDates = [];
  preAvailabilities: Availability[] = [];
  private readonly notifier: NotifierService;

  selectedDate: String;
  selectedMonth: string;
  selectedYear: string;
  selectModalVisible = false;


  constructor(private localeService: BsLocaleService,
    private router: Router,
    private programService: ProgramService,
    private availabilityService: AvailabilitiesService,
    private state: StateService,
    private notifierService: NotifierService,
    private location: Location) {
    this.notifier = notifierService;
    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    enGbLocale.week.dow = 0;
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');

  }

  ngOnInit(): void {
    if (navigator.platform.includes('Mac')) {
      document.getElementById('setAvailability').classList.add('mac-setAvailability');
    }
    !this.state.hasToken() && this.router.navigate(['/']);
    this.getProgram();
    this.getAvailabilitiesForMonth();
  }


  onValueChange(selDate: Date) {
    var date = this.getDateItem(selDate);
    const index = this.selectedDates.findIndex(item => format(item, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'));

    console.log({ date, index });

    index < 0 ? this.selectedDates.push(date) : this.selectedDates.splice(index, 1);

    const customClasses =
      this.prevDates.map(date => ({ date: new Date(date), classes: ['prev-date'] })).concat(
        this.selectedDates.map(date => ({ date: new Date(date), classes: ['curr-date'] })));
    this.datePicker['_datepickerRef'].instance.dateCustomClasses = customClasses;
  }

  ///

  private getDateItem(date: Date) {
    var d = new Date(date);
    return d;
  }


  getAvailabilitiesForMonth() {
    var tempDate: any[] = [];
    this.availabilityService
      .getAvailabilitieByDate(this.state.user.id,
        new Date().toISOString(),
        null)
      .subscribe(a => {
        this.preAvailabilities = a.map( d => {
          let t = utcToZonedTime(d.startDate, this.state.user.tzCanonicalName);
          let zonedConverted = new Date(t.getFullYear(), t.getMonth(), t.getDate(), t.getHours(), t.getMinutes());
          d.startDate = zonedConverted.toISOString();
          var endDate = new Date(d.startDate);
          endDate.setMinutes(endDate.getMinutes() + 30);
          const startTime = format(new Date(d.startDate), 'hh:mm a');
          const endTime = format(endDate, 'hh:mm a');
          var title = startTime + "-" + endTime;
          this.events.push({start: new Date(d.startDate), title: title, end: endDate, id: d.id, color: this.colors.green});
          return d;
        });

        this.refresh.next();

        a.forEach(e => {
          var d = new Date(e.startDate);
          if (!tempDate.some((item) => format(item, 'yyyy-MM-dd') === format(d, 'yyyy-MM-dd'))) {
            tempDate.push(d);
          }
        });
        this.datePicker?.bsValueChange.asObservable().subscribe(date => console.log('Value:', date));
        this.prevDates = tempDate;
        this.dateCustomClasses = this.prevDates.map(date => ({
          date: new Date(date), classes: ['prev-date']
        }));
        if (this.datePicker)
        this.datePicker['_datepickerRef'].instance.dateCustomClasses = this.dateCustomClasses;

      });
  }

  getProgram() {
    this.programService.getProgram()
      .subscribe(p => {
        this.program = p[0];
        this.localeService.use('en-gb');
        this.timings = this.generateTimings('07:00 AM');
        this.addTimings();
      });
  }

  toggleDropdown(event: MouseEvent) {
    const elem = event.target as HTMLElement;
    const selectElem = elem.closest('div.ke-select');

    selectElem.classList.toggle('open');
  }

  selectStart(start: string, index: number) {
    this.availablility[index].stime = start;
    document.querySelector('#confirm-popup h3').scrollIntoView();
  }

  selectEnd(end: string, index: number) {
    this.availablility[index].etime = end;
    document.querySelector('#confirm-popup h3').scrollIntoView();
  }

  deleteTimings(index: number) {
    this.availablility.splice(index, 1);
    if (this.availablility.length < 1) {
      this.addTimings();
    }
    this.toDdelete = true;
  }

  addTimings() {
    this.availablility
      .push({
        stime: null,
        etime: null
      });
  }

  addInitialTimings() {
    this.availablility = [];
    let selectedDatePreAvailabilities = [];
    if (this.selectedDates.length === 1) {
      this.selectedDates.forEach(e => {
        e = format(e, 'yyyy-MM-dd');
        selectedDatePreAvailabilities = this.preAvailabilities.filter(a => {
          return format(new Date(a.startDate), 'yyyy-MM-dd') === e;
        });
      });
    }

    if (selectedDatePreAvailabilities.length < 1) {
      this.addTimings();
      return;
    }

    selectedDatePreAvailabilities.forEach(t => {
      this.availablility.push({
        stime: format(new Date(t.startDate), 'hh:mm a'),
        etime: format(new Date(new Date(t.startDate).getTime() + this.program.sessionDuration * 60000), 'hh:mm a')
      })
    })
  }

  goTimeSection() {
    this.timeSectionVisible = true;
    this.availablility = [];
    this.addInitialTimings();
    this.errorMessage = '';
  }

  goDateSection() {
    this.selectedDates = [];
    this.availablility = [];
    this.errorMessage = '';
    this.getAvailabilitiesForMonth();
    this.timeSectionVisible = false;
  }

  addAvailabilities() {
    this.disabledButton = true;
    this.errorMessage = '';
    const length = this.availablility.length;

    let startDates = this.calculateStarts();
    const seperatedPayload = this.filterNewAvailabilities(startDates);
    startDates = seperatedPayload.newStartDates;
    if (this.selectedDates.length === 1 && seperatedPayload.toBeDelete.length > 0) {
      this.removeAvailabilities(seperatedPayload.toBeDelete);
    }
    if (!this.toDdelete && (this.availablility[length - 1].stime === null || this.availablility[length - 1].etime === null)) {
      this.errorMessage = 'Please select start and end time if you want to add availabilities.';
      this.disabledButton = false;
      return;
    }
    if (startDates.length < 1) {
      this.router.navigate(['/upcoming-sessions-faculty']);
      this.disabledButton = false;
      return;
    }

    startDates = startDates.map( d => {
      let zonedConverted = zonedTimeToUtc(d, this.state.user.tzCanonicalName);
      return zonedConverted.toISOString();
    });


    const availabilityData = { captainId: this.state.user.id, startDate: startDates };

    this.availabilityService.addAvailability(availabilityData)
      .subscribe(
        this.handleResponse.bind(this));
  }

  removeAvailabilities(toBeDelete: Availability[]) {
    this.disabledButton = true;
    toBeDelete.forEach(d => {
      this.availabilityService.deleteAvailability(d.id)
        .subscribe(
          this.handleDeleteResponse.bind(this));
    });
    toBeDelete.length > 0 && this.notifier.notify('error', 'Availabilities Deleted!');
  }

  ///

  generateTimings(str: string): string[] {
    let timeArr = str.substr(0, 5).split(':');
    let s = parseInt(timeArr[0]) + (timeArr[1] === '00' ? 0 : 0.5);
    let x = this.program.sessionDuration;
    let times = [];
    let st = s * 60;
    let et = 21.5;
    let ap = [' AM', ' PM'];

    for (let i = 0; st < et * 60; i++) {
      let hh = Math.floor(st / 60);
      let mm = (st % 60);
      times[i] = ('0' + ((hh % 12 === 0) ? 12 : hh % 12)).slice(-2) + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)];
      st = st + x;
    }
    return times;
  }

  calculateStarts() {
    let startDate = [];
    const length = this.availablility.length;
    this.selectedDates.forEach(s => {
      this.availablility.forEach(t => {
        if (t.stime !== null) {
          startDate.push(new Date(format(s, 'MM/dd/yyyy') + ' ' + t.stime));
          let stIdx = this.timings.indexOf(t.stime);
          let etIdx = this.timings.indexOf(t.etime);
          if (stIdx < etIdx - 1) {
            let diff = etIdx - stIdx;
            for (let i = 1; i < diff; i++) {
              startDate.push(new Date(format(s, 'MM/dd/yyyy') + ' ' + this.timings[stIdx + i]));
            }
          }
        }
      });
    })
    return startDate;
  }

  filterNewAvailabilities(startDates) {
    let selectedDatePreAvailabilities = [];
    this.selectedDates.forEach(e => {
      e = format(e, 'yyyy-MM-dd');
      var temp = [];
      temp = this.preAvailabilities.filter(a => {
        const date = new Date(a.startDate);
        return format(date, 'yyyy-MM-dd') === e;
      });
      temp.forEach(t => {
        selectedDatePreAvailabilities.push(t);
      });
    })
    let toBeDelete = selectedDatePreAvailabilities;
    let newStartDates = startDates;

    selectedDatePreAvailabilities.forEach(t => {
      newStartDates = newStartDates.filter(s => {
        return toDate(t.startDate, { timeZone: 'UTC' }).getTime() - toDate(s, { timeZone: 'UTC' }).getTime();
      });
    })

    startDates.forEach(s => {
      toBeDelete = toBeDelete.filter(t => {
        return toDate(t.startDate, { timeZone: 'UTC' }).getTime() - toDate(s, { timeZone: 'UTC' }).getTime();
      });
    })

    return { newStartDates, toBeDelete };
  }

  handleResponse(success) {
    console.log(success);
    if (success instanceof Error) {
      if(success.message === 'An invalid date value was passed'){
        this.errorMessage =  'Selected date is invalid!';
      } else {
        this.errorMessage = "Something went wrong. Please try again!";
      }
    }
    else {
      if (success) {
        this.router.navigate(['/upcoming-sessions-faculty']);
        this.notifier.notify('error', 'New Availabilities Created!');
      }
    }
    this.disabledButton = false;
  }

  handleDeleteResponse(success) {
    console.log(success);
    if (success instanceof Error) {
      this.errorMessage = "Something went wrong. Please try again!";
    }
    else {
      if (success) {
        this.router.navigate(['/upcoming-sessions-faculty']);
      }
    }
    this.disabledButton = false;
  }

  goBack() {
    this.location.back();
  }
  handleEvent(action: string, event: CalendarEvent): void {
    console.log('Event clicked', event);
    // this.calculateAvailableDates(event);
    // var selectedDate: Date = event.start;
    // this.selectedDate = selectedDate.getDate().toString();
    // this.selectedYear = selectedDate.getFullYear().toString();
    // this.selectedMonth = selectedDate.toLocaleString('default', { month: 'long' });
    // this.showSelectModal();
  }

  showSelectModal() {
    this.selectModalVisible = true;
  }

  closeSelectModal() {
    this.selectModalVisible = false;
  }

  dayClicked({ date}: { date: Date }): void {
    var selectedDate: Date = date;
    this.selectedDate = selectedDate.getDate().toString();
    this.selectedYear = selectedDate.getFullYear().toString();
    this.selectedMonth = selectedDate.toLocaleString('default', { month: 'long' });
    this.selectedDates = [];
    this.selectedDates.push(selectedDate);
    this.addInitialTimings();
    this.showSelectModal();
  }
}

