<div class="column right-signup">
  <section id="logo-header-mobile">

  </section>
    <section id="register">
        <div>
            <div class="">
                <div class="col">
                    <h1 class="text-center">
                        Build your profile by answering the questions below.
                    </h1>
                </div>
            </div>
            <div class="col text-center desktop-hide">
              <img src="assets/images/process-step-one-m.png" class="img-fluid step-image-padding">
            </div>
            <div class="col text-center mobile-hide">
              <img src="assets/images/process-step-one.png" class="img-fluid step-image-padding">
            </div>
            <form (submit)="doRegister()" [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <div class="input-group input-group-lg"
                            [ngClass]="{ 'input-group-error': isFieldInvalid('firstName') }">
                            <input type="text" class="form-control" name="firstName" formControlName="firstName"
                                placeholder="First Name" tabindex="1">
                        </div>
                        <div class="validation-error-message" *ngIf="isFieldInvalid('firstName')">
                            <span>Please enter a valid First Name</span>
                        </div>

                        <div class="input-group input-group-lg"
                            [ngClass]="{ 'input-group-error': isFieldInvalid('lastName') }">
                            <input type="text" class="form-control" name="lastName" formControlName="lastName"
                                placeholder="Last Name" aria-label="Last Name" tabindex="2">
                        </div>
                        <div class="validation-error-message" *ngIf="isFieldInvalid('lastName')">
                            <span>Please enter a valid Last Name</span>
                        </div>

                        <div class="input-group input-group-lg"
                            [ngClass]="{ 'input-group-error': isFieldInvalid('emailAddress') }">
                            <input type="text" class="form-control" name="emailAddress" formControlName="emailAddress"
                                placeholder="Email Address" aria-label="Email Address" tabindex="3">
                        </div>
                        <div class="validation-error-message" *ngIf="isFieldInvalid('emailAddress')">
                            <span>Please enter a valid email</span>
                        </div>

                        <div class="input-group input-group-lg"
                            [ngClass]="{ 'input-group-error': isFieldInvalid('phone') }">
                            <input type="tel" class="form-control" name="phone" formControlName="phone"
                                placeholder="Mobile Phone" aria-label="Mobile Phone" tabindex="4">
                        </div>
                        <div class="validation-error-message" *ngIf="isFieldInvalid('phone')">
                            <span>Please enter a valid mobile phone number</span>
                        </div>

                        <div class="mx-auto ke-wrapper-desktop">
                          <div class="ke-select-wrapper" (click)="toggleDropdown($event)" [ngClass]="timezoneFieldValidState ? '' : 'input-group-error'">
                            <div class="ke-select" tabindex="5" (focus)="toggleDropdown($event)" (blur)="closeDropdown($event)">
                              <div class="ke-select__trigger" #selectTrigger>
                                <div class="label-area">
                                  <span>{{ selectedTimezone || 'Timezone' }}</span>
                                </div>
                                <div class="arrow">
                                  <img src="assets/images/filled-up-arrow.svg" />
                                </div>
                              </div>
                              <div class="custom-options">
                                                <span class="custom-option" *ngFor="let t of timezones"
                                                      [class.selected]="t.id === selectedTimezoneId"
                                                      (click)="selecTimezone(t)">{{ t.label }} ({{t.abbr}})</span>
                              </div>

                            </div>
                          </div>
                          <div class="validation-error-message" *ngIf="!timezoneFieldValidState">
                            <span>Please select timezone</span>
                          </div>
                        </div>

                        <div class="input-group input-group-lg"
                            [ngClass]="{ 'input-group-error': isFieldInvalid('password') }">
                            <input type="password" class="form-control" name="password" formControlName="password"
                                placeholder="Password" aria-label="Confirm Password" tabindex="6">
                        </div>
                        <div class="validation-error-message"
                            *ngIf="isFieldInvalid('password') && form.controls.password.errors?.required">
                            <span>Please enter a password</span>
                        </div>
                        <div class="validation-error-message"
                            *ngIf="isFieldInvalid('password') && form.controls.password.errors?.rangeLength">
                            <span>Please enter a password between 5 and 25 characters</span>
                        </div>
                        <div class="input-group input-group-lg"
                            [ngClass]="{ 'input-group-error': isFieldInvalid('confirmPassword')}">
                            <input type="password" class="form-control" name="confirmPassword"
                                formControlName="confirmPassword" placeholder="Confirm Password"
                                aria-label="Confirm Password" tabindex="7">
                        </div>
                        <div class="validation-error-message"
                            *ngIf="isFieldInvalid('confirmPassword') && form.controls.confirmPassword.errors?.required">
                            <span>Please enter a password</span>
                        </div>
                        <div class="validation-error-message"
                            *ngIf="isFieldInvalid('confirmPassword') && form.controls.confirmPassword.errors?.equalTo">
                            <span>Both passwords should match</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col t-a-c">
                        <button type="submit" class="btn btn-primary" tabindex="8">Next</button>
                    </div>
                </div>

                <div class="validation-error-message" *ngIf="errorMessage && submitted">
                    <span>{{errorMessage}}</span>
                </div>


            </form>
          <p class="purple sign-up-msg">This program is exclusively for care partners aged 18 and above</p>

        </div>

        <!-- Terms Modal -->
        <div id="terms-popup" [hidden]="!termsVisible">
            <div class="terms-body">
                <div class="terms-text" #termsText>
                    <button type="button" class="menu-close close" aria-label="Close" (click)="closeTerms()">
                        <span aria-hidden="true">
                            <img src="assets/images/close_black.svg" width="20">
                        </span>
                    </button>
                    <div class="text-center mb-3">
                        <h1>OA MY WAY<sup>&reg;</sup> Live <br> Terms of Use
                        </h1>
                    </div>
                    <p>
                        There is no cost to register for this support program. The sessions for this program are private and
                        confidential. We do insist that registered users abide by the rules and policies detailed below.
                    </p>

                    <p>
                        By registering, you agree not to provide any content which is defamatory, abusive, hateful,
                        threatening, spam or spam-like, likely to offend, contains adult or objectionable content, contains
                        personal information of others, risks copyright infringement,
                        encourages unlawful activity, or otherwise violates any laws.
                    </p>

                    <p>
                        The owners of OA MY WAY Live (OAMW) Live reserve the right to remove noncompliant members for any
                        reason. Users deemed by the owners to be abusing the program may be banned with or without warning.
                    </p>

                    <p>
                        The providers ("we," "us," "our") of the service provided by this web site ("Service") are not
                        responsible for any user generated content ("Content"). Content posted expresses the views of its
                        author only.
                    </p>

                    <p>
                        We reserve the right to remove or modify any Content posted for any reason without explanation.
                        Requests for Content to be removed or modified will be undertaken only at our discretion. We reserve
                        the right to take action against any account with the
                        Service at any time.
                    </p>

                    <p>You are granting us with a nonexclusive, permanent, irrevocable, unlimited license to use, publish,
                        or re-publish your Content in connection with the Service.</p>

                    <p>All Content you submit, or upload may be reviewed by staff members. Do not submit any Content that
                        you consider to be private or confidential.</p>

                    <p>These terms may be changed at any time without notice.</p>

                    <p>If you do not agree with these terms, please do not register, or use this Service.</p>

                    <p><strong>Terms of Use Disclaimer</strong></p>

                    <p>
                        OAMW Live Ambassadors provide general information about their personal experience with joint
                        replacement surgery and are being compensated for their services. A considerable effort has been
                        made in good faith to ensure that material accessible from this
                        site is accurate. Despite this effort, it is clear that errors are inevitable. Consequently, no
                        guarantees are expressed or implied as to the accuracy, timeliness, currency, or completeness of any
                        information authored by persons at
                        or agents of OAMW Live or its affiliates, or accessible using links from this site. Nor is any
                        warranty made that the information obtained from this site or that of an affiliate is valuable or
                        useful for any purpose. A user assumes
                        full responsibility for any actions taken based on information obtained from this website. In
                        particular, we emphasize that the information available through this site should not be interpreted
                        as medical or professional advice. All
                        medical information, from this or any other source, needs to be carefully reviewed with your
                        personal health care provider before being acted upon in any way. Sessions may be monitored and
                        recorded for record-keeping, training, and
                        quality-assurance purposes.
                    </p>

                    <p><strong>Acceptable Use</strong></p>

                    <p>
                        Use of information from this program for commercial purposes, other than those approved by OAMW
                        Live, is not permitted. Use of OAMW Live icons and logos is restricted: these icons and logos may
                        not be used without the permission of OAMW Live. Use of US
                        mail addresses, email addresses, or phone numbers for the purpose of contacting individuals at OAMW
                        Live for any purpose other than the conduct of the business of the organization is not permitted.
                    </p>

                    <p><strong>Copyright</strong></p>

                    <p>
                        Unless otherwise noted, all documents and conversations accessed from this program are viewed as
                        property of OAMW Live and are Copyright, DePuy Synthes all rights reserved. Use of any data or other
                        materials accessed from this site without the permission
                        of the authors may result in action being taken against offenders.
                    </p>

                    <span #termsEnd></span>
                    <div>
                        <button type="button" class="btn btn-primary bg-purple" (click)="acceptTerms()"
                            [disabled]="!termsRead">Accept</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
