<div class="body-height">
    <section id="unsubscribe" class="bg-grey">
        <div class="container">
            <div class="text-center">
              <img src="assets/images/voce/logo.png" class="landing-logo">
            </div>
            <div *ngIf="!unsubscribed">
              <div class="row">
                  <div class="col text-center">
                      <h1>Are you sure you do not want to receive communications?</h1>
                      <p class="description m-0">If you unsubscribe, you will not receive future<br /> reminders for upcoming program sessions.</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col text-center">
                      <button type="button" class="btn btn-secondary btn-desktop bg-purple" (click)="unsubscribe()">Unsubscribe</button>
                  </div>
              </div>
            </div>
            <div id="unsubscribed" class="bg-grey" *ngIf="unsubscribed">
              <div class="container">
                <div class="row">
                  <div class="col text-center">
                    <h1>You are now unsubscribed and have been<br class="mobile-hide" /> removed from receiving communications.</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="container footer-container p-0">
              <div class="divider-container">
                <div class="divider">
                  &nbsp;
                </div>
              </div>
              <div class="footer">
                <div class="row">
                  <div class="width-30 m-width-100">
                    <img src="assets/images/voce/JJlogo-d.png" class="jj-logo">
                  </div>
                  <div class="width-64 m-width-100">
                    <p class="footer-text">This site is published by Janssen Pharmaceuticals, Inc., which is solely responsible for its contents. The material on this
                      site is intended only as informational or as an educational aid and it is not intended to be taken as medical advice. The
                      ultimate responsibility for patient care resides with a healthcare professional.</p>
                    <p class="footer-text">This information is intended for the use of patients and caregivers in the United States and Puerto Rico only. Laws,
                      regulatory requirements, and medical practices for pharmaceutical products vary from country to country. The Prescribing
                      Information included here may not be appropriate for use outside the United States and Puerto Rico.</p>
                    <p class="footer-text">Third party trademarks used herein are trademarks of their respective owners.</p>
                    <p class="footer-text">
                      Last updated September 2024<span class="spacer-padding">|</span>cp-473097v1
                    </p>
                    <p class="footer-text">
                      <a href="https://www.janssen.com/us/contact-us" class="f-link">Contact Us</a>
                      <a href="https://www.hopeforschizophrenia.com/sitemap/" class="f-link" >Site Map</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="divider-container">
                <div class="f-divider">
                  &nbsp;
                </div>
              </div>
              <div class="footer m-pt-6">
                <div class="row m-column-reverse">
                  <div class="width-30 align-self-end m-width-100">
                    <p class="desktop-hide m-0" style="font-size: 3vw">&nbsp;</p>
                    <p class="footer-text">&copy; Johnson & Johnson and its affiliates 2024</p>
                  </div>
                  <div class="width-64 m-width-100">
                    <p class="footer-text">
                      <a href="https://innovativemedicine.jnj.com/us/privacy-policy" class="f-link text-decoration-none">Privacy Policy</a>
                      <a href="https://www.janssen.com/us/legal-notice" class="f-link text-decoration-none">Legal Notice</a>
                      <a href="https://www.hopeforschizophrenia.com/cookie-policy/" class="f-link text-decoration-none">Cookie Policy</a><br class="d-hide">
                      <a href="https://www.hopeforschizophrenia.com/cookie-policy/" class="f-link text-decoration-none">Customize Cookie Settings</a>
                    </p>
                    <p class="footer-text">
                      <a href="https://privacyportal-cdn.onetrust.com/dsarwebform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/ac3f202f-da7f-436a-b609-7f2364c326b6.html?WebsiteName=connectedbyhope.com" class="f-link text-decoration-none"><span>Do not sell or share my personal information</span></a>
                      <a href="https://privacyportal-cdn.onetrust.com/dsarwebform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/ac3f202f-da7f-436a-b609-7f2364c326b6.html?WebsiteName=connectedbyhope.com" class="f-link text-decoration-none"><span class="spacer-padding">Limit the use of my sensitive personal information</span></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
</div>
