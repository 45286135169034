<div class="grad1">
  <section id="forgot-pwd">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <a href="/"><img src="assets/images/voce/logo.png" class="logo-login"></a>
          </div>
        </div>
        <div class="login-box mobile-hide">
          <form (submit)="doForgotPassword()" [formGroup]="form">
              <!--<div class="row desktop-hide">
                <div class="col text-center">
                  <a href="/"><img src="assets/images/voce/VoceLogo-White.png" class="logo-login"></a>
                </div>
              </div>-->
              <div class="row input-box">
                <div class="col page-description text-black text-center">
                  <p>Please enter your email address and press
                    “Submit” to receive an email with instructions on
                    how to reset your password</p>
                </div>
              </div>
              <div class="row input-box">
                <div class="col">
                  <div class="input-group input-group-lg" [ngClass]="{ 'input-group-error': isFieldInvalid('email') }">
                    <input type="text" class="form-control" name="email" formControlName="email" placeholder="EMAIL"
                      aria-label="Username" aria-describedby="basic-addon1">
                  </div>
                  <div class="validation-error-message" *ngIf="isFieldInvalid('email')">
                    <span>Please enter a valid email address</span>
                  </div>
                </div>
              </div>
              <div class="input-box msg">
                <span *ngIf="success">Password reset link has been sent to your email! <a [routerLink]="['/login']">Back to login</a></span>
              </div>
              <div class="row input-box">
                <div class="col text-center">
                  <button type="submit" class="btn btn-primary btn-desktop bg-purple">Submit</button>
                </div>
              </div>
          </form>
        </div>
        <div class="login-box-mobile desktop-hide">
          <form (submit)="doForgotPassword()" [formGroup]="form">
            <!--<div class="row desktop-hide">
              <div class="col text-center">
                <a href="/"><img src="assets/images/voce/VoceLogo-White.png" class="logo-login"></a>
              </div>
            </div>-->
            <div class="row input-box">
              <div class="col page-description text-black text-center">
                <p class="m-0">Please enter your email address and press
                  “Submit” to receive an email with instructions on
                  how to reset your password</p>
              </div>
            </div>
            <div class="row input-box">
              <div class="col">
                <div class="input-group input-group-lg" [ngClass]="{ 'input-group-error': isFieldInvalid('email') }">
                  <input type="text" class="form-control" name="email" formControlName="email" placeholder="EMAIL"
                         aria-label="Username" aria-describedby="basic-addon1">
                </div>
                <div class="validation-error-message" *ngIf="isFieldInvalid('email')">
                  <span>Please enter a valid email address</span>
                </div>
              </div>
            </div>
            <div class="msg text-center">
              <span *ngIf="success" class="purple">Password reset link has been sent to your email! <a [routerLink]="['/login']">Back to login</a></span>
            </div>
            <div class="row input-box">
              <div class="col text-center">
                <button type="submit" class="btn btn-primary btn-desktop bg-purple">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
  </section>
</div>
