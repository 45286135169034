import { Injectable, ElementRef, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import type { LiveTranscriptionClient, VideoClient, Stream } from '@zoom/videosdk';
import ZoomVideo from '@zoom/videosdk';
import { NotifierService } from 'angular-notifier';

import { StateService } from './state.service';
import { PixiService } from './pixi.service';


@Injectable({ providedIn: 'root' })
export class ZoomService {

  private localContainer: ElementRef<HTMLDivElement>;
  private remoteContainer: ElementRef<HTMLDivElement>;
  private localVideo: ElementRef<HTMLVideoElement|HTMLCanvasElement>;
  private remoteVideo: ElementRef<HTMLCanvasElement>;

  private networkLevel$ = new BehaviorSubject<number>(null);
  private networkLevelRemote$ = new BehaviorSubject<number>(null);

  private videoTrackStatus$ = new BehaviorSubject<boolean>(false);
  private videoTrackStatusRemote$ = new BehaviorSubject<boolean>(false);

  private audioTrackStatus$ = new BehaviorSubject<boolean>(true);
  private audioTrackStatusRemote$ = new BehaviorSubject<boolean>(false);

  private sessionStarted$ = new BehaviorSubject<boolean>(false);
  private sessionEnded$ = new BehaviorSubject<boolean>(false);

  private isConnected$ = new BehaviorSubject<boolean>(false);

  private zoomClient: typeof VideoClient;
  private liveTscClient: typeof LiveTranscriptionClient;

  private adrResult$ = new BehaviorSubject<any>({});
  private readonly notifier: NotifierService;

  constructor(
    private state: StateService, 
    private pixi: PixiService, 
    private notifierService: NotifierService) {
    this.notifier = notifierService;
  }


  get adrResult() {
    return this.adrResult$.asObservable();
  }

  get sessionStarted() {
    return this.sessionStarted$.asObservable();
  }

  get sessionEnded() {
    return this.sessionEnded$.asObservable();
  }


  async initLocalMedia(localContainer: ElementRef<HTMLDivElement>,
                       localVideo: ElementRef<HTMLVideoElement|HTMLCanvasElement>) {
    const zoomSession = this.getZoomSession();
    this.localContainer = localContainer;
    this.localVideo = localVideo;

    await zoomSession.startAudio();
    this.audioTrackStatus$.next(true);

    // start live transcription
    this.liveTscClient && await this.liveTscClient.startLiveTranscription();

    this.localContainer.nativeElement.classList.add('active');

    if (this.state.sessionType === 'audio') {
      return;
    }

    if (zoomSession.isRenderSelfViewWithVideoElement())
      await zoomSession.startVideo({ videoElement: this.localVideo.nativeElement as HTMLVideoElement });
      // video successfully started and rendered
    else {
      await zoomSession.startVideo();
      await zoomSession.renderVideo(this.localVideo.nativeElement,
                                    this.zoomClient.getCurrentUserInfo().userId,
                                    undefined, undefined, 0, 0, 3);
                                    
      const canvas = document.body.querySelectorAll('video');
      if (canvas.length > 0) {
        console.log(canvas);
        const localVideo = canvas[1];
        localVideo.style.position = '';
        localVideo.style.left = '';
        localVideo.style.top = '';
        this.localContainer.nativeElement.childNodes.forEach(childNode => {
          if (childNode.nodeName.toLowerCase() === 'video') {
              childNode.remove();
          }
        });
        this.localContainer.nativeElement.appendChild(localVideo);
      }
      // video successfully started and rendered
    }

    this.localContainer.nativeElement.classList.remove('no-video');
    this.videoTrackStatus$.next(true);
  }

  async setupRemoteMedia(remoteContainer: ElementRef<HTMLDivElement>, remoteVideo: ElementRef<HTMLCanvasElement>){
    this.remoteContainer = remoteContainer;
    this.remoteVideo = remoteVideo;
    const zoomSession = this.getZoomSession();
    const allUsers = this.zoomClient.getAllUser();

    for (const user of allUsers) {
      if (user.userId === this.zoomClient.getCurrentUserInfo().userId)
        continue;

      console.log('setupRemoteMedia:', user);

      this.remoteContainer.nativeElement.classList.add('active');
      this.remoteContainer.nativeElement.classList.remove('no-indicators');
      this.sessionStarted$.next(true);
      if (user.bVideoOn){
        const canvas = this.remoteVideo.nativeElement;
        const heightRatio = 16 / 9;
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientWidth / heightRatio;
        await zoomSession.renderVideo(this.remoteVideo.nativeElement,
                                      user.userId,
                                      canvas.clientWidth,
                                      canvas.clientWidth / heightRatio, 0, 0, 3);
        this.remoteContainer.nativeElement.classList.remove('no-video');
      }
      const networkQ = this.getZoomSession().getNetworkQuality(user.userId);
      networkQ && 'uplink' in networkQ && this.displayNetworkIndicator(this.remoteContainer.nativeElement, networkQ.uplink);
      this.videoTrackStatusRemote$.next(user.bVideoOn);
      // Whether audio is muted. If the user is not joined to audio (not connected to the microphone), the value is undefined
      this.audioTrackStatusRemote$.next(user.muted !== undefined ? !user.muted : true);
    }

    this.setUpHandlers();
  }

  async disposeLocalMedia() {
    if (!this.zoomClient)
      return;

    if (this.state.sessionType === 'video')
      await this.getZoomSession().stopVideo();

    await this.getZoomSession().stopAudio();

    // TODO destroy the local and remote tracks.
    this.localContainer.nativeElement.classList.remove('active');
    this.localContainer.nativeElement.classList.add('no-video');
    // this.localContainer = null;
    // this.localVideo = null;
    // this.remoteContainer = null;
    // this.remoteVideo = null;
  }

  async joinSession() {
    try {
      this.zoomClient = ZoomVideo.createClient();
      await this.zoomClient.init('en-US', 'CDN', { patchJsMedia: true, leaveOnPageUnload: true, stayAwake: true}); // patchJsMedia: true
      await this.zoomClient.join(this.state.activeSession, this.state.twilioToken, this.state.user.firstName);

      if (this.state.user.type === 'CAPTAIN')
        this.liveTscClient = this.zoomClient.getLiveTranscriptionClient();
    } catch (error) {
      console.log(error);
    }
  }

  leaveSession(endSession: boolean) {
    if (!this.zoomClient)
      return;

    this.zoomClient.leave(endSession);
    this.removeZoomEventHandlers();
    ZoomVideo.destroyClient();
    this.zoomClient = undefined;
    this.sessionStarted$.next(false);
  }

  toggleAudio() {
    const muted  = this.getZoomSession().isAudioMuted();
    !muted
      ? this.getZoomSession().muteAudio()
      : this.getZoomSession().unmuteAudio();

    this.audioTrackStatus$.next(muted);
  }

  async toggleVideo() {
    const isCapturingVideo  = this.getZoomSession().isCapturingVideo();
    if (!isCapturingVideo) {
      if (this.getZoomSession().isRenderSelfViewWithVideoElement())
        await this.getZoomSession().startVideo({ videoElement: this.localVideo.nativeElement as HTMLVideoElement });
      else {
        await this.getZoomSession().startVideo();
        await this.getZoomSession().renderVideo(this.localVideo.nativeElement,
                                                this.zoomClient.getCurrentUserInfo().userId,
                                                undefined, undefined, 0, 0, 3);

        const canvas = document.body.querySelectorAll('video');
        if (canvas.length > 0) {
          console.log(canvas);
          const localVideo = canvas[1];
          localVideo.style.position = '';
          localVideo.style.left = '';
          localVideo.style.top = '';
          this.localContainer.nativeElement.childNodes.forEach(childNode => {
            if (childNode.nodeName.toLowerCase() === 'video') {
                childNode.remove();
            }
          });
          this.localContainer.nativeElement.appendChild(localVideo);
        }
        // video successfully started and rendered
      }

      this.localContainer.nativeElement.classList.remove('hidden');
      this.localContainer.nativeElement.classList.remove('no-video');
    } else {
      await this.getZoomSession().stopVideo();
      await this.getZoomSession().detachVideo(this.zoomClient.getCurrentUserInfo().userId);
      this.localContainer.nativeElement.classList.add('hidden');
      this.localContainer.nativeElement.classList.add('no-video');
    }

    this.videoTrackStatus$.next(!isCapturingVideo);
  }

  get networkLevel() {
    return this.networkLevel$.asObservable();
  }

  get networkLevelRemote() {
    return this.networkLevelRemote$.asObservable();
  }

  get videoTrackStatusRemote() {
    return this.videoTrackStatusRemote$.asObservable();
  }

  get videoTrackStatus() {
    return this.videoTrackStatus$.asObservable();
  }

  get audioTrackStatusRemote() {
    return this.audioTrackStatusRemote$.asObservable();
  }

  get audioTrackStatus() {
    return this.audioTrackStatus$.asObservable();
  }

  get isConnected() {
    return this.isConnected$.asObservable();
  }

  setIsConnected(status: boolean) {
    this.isConnected$.next(status);
  }

  ///

  private getZoomSession() {
    return this.zoomClient && this.zoomClient.getMediaStream();
  }

  private setUpHandlers() {
    this.zoomClient.on('caption-message', this.captionMessageHandler.bind(this));
    this.zoomClient.on('user-added', this.userAddedHandler.bind(this));
    this.zoomClient.on('user-updated', this.userUpdatedHandler.bind(this));
    this.zoomClient.on('user-removed', this.userRemoved.bind(this));
    this.zoomClient.on('connection-change', this.connectionChangedHandler.bind(this));
    this.zoomClient.on('peer-video-state-change', this.peerVideoChangedHandler.bind(this));
    this.zoomClient.on('network-quality-change', this.networkQualityChangeHandler.bind(this));
    this.zoomClient.on('video-active-change', this.monitorVideoStatus.bind(this));
  }

  @HostListener('window:beforeunload')
  private removeZoomEventHandlers(){
    if (!this.zoomClient)
      return;

    this.zoomClient.off('caption-message', this.captionMessageHandler.bind(this));
    this.zoomClient.off('user-added', this.userAddedHandler);
    this.zoomClient.off('user-updated', this.userUpdatedHandler);
    this.zoomClient.off('user-removed', this.userRemoved);
    this.zoomClient.off('connection-change', this.connectionChangedHandler);
    this.zoomClient.off('peer-video-state-change', this.peerVideoChangedHandler.bind(this));
    this.zoomClient.off('network-quality-change', this.networkQualityChangeHandler);
    this.zoomClient.off('video-active-change', this.monitorVideoStatus.bind(this));
  }


  private async captionMessageHandler(payload: { displayName: string, done: boolean, language: string, text: string }) {
    if (!this.zoomClient || !payload.done)
      return;

    let text = '———';

    if (this.state.user.type === 'CAPTAIN' && this.state.user.firstName !== payload.displayName)
      text = payload.text;

    // this.pixi
    //     .analyzeCaption(this.state.activeSession, text)
    //     .subscribe(result => this.adrResult$.next(result),
    //                err => console.log(err));
  }

  private userUpdatedHandler(payload) {
    if (!this.zoomClient)
      return;

    console.log('userUpdatedHandler:', payload);
    
    const userUpdatedEvent = payload.length && payload[0];
    // const currUserId = this.zoomClient && this.zoomClient.getCurrentUserInfo() && this.zoomClient.getCurrentUserInfo().userId;
    const currUserId = this.zoomClient?.getCurrentUserInfo()?.userId;
    if (userUpdatedEvent.userId !== currUserId) {
      this.remoteContainer.nativeElement.classList.remove('no-indicators');
    }
    
    if ('muted' in userUpdatedEvent && userUpdatedEvent.userId !== currUserId) {
      this.audioTrackStatusRemote$.next(!userUpdatedEvent.muted);
      console.log('user-user-updated', userUpdatedEvent);
      console.log('user-user-updated, curr userid',  this.zoomClient.getCurrentUserInfo().userId);
    }
  }

  private userAddedHandler(payload){
    console.log('user-added', payload);
    console.log('user-added, curr userid',  this.zoomClient.getCurrentUserInfo().userId);

    this.remoteContainer.nativeElement.classList.add('active');
    this.remoteContainer.nativeElement.classList.remove('no-indicators');

    this.sessionStarted$.next(true);
  }

  private userRemoved(payload) {
    console.log('user-removed', payload);
    console.log('user-removed, curr userid',  this.zoomClient.getCurrentUserInfo().userId);
    // this._zoomSession.stopRenderVideo(this.remoteContainer.nativeElement,payload.userId);
    this.remoteContainer.nativeElement.classList.remove('active');
    this.remoteContainer.nativeElement.classList.add('no-video');
    this.remoteContainer.nativeElement.classList.add('no-indicators');
    
    this.networkLevelRemote$.next(0);

    if (this.state.user.type === 'PARTICIPANT')
      this.sessionEnded$.next(true);
  }

  private connectionChangedHandler(payload){
    // console.log('connection-change', payload);
    // console.log("connection-change, curr userid",  this._zoomClient.getCurrentUserInfo().userId);
  }

  private async peerVideoChangedHandler(payload){
    console.log('peerVideoChangedHandler', payload);

    if (payload.action === 'Start') {
      const heightRatio = 16 / 9;
      let canvas = this.remoteVideo.nativeElement;
  
      const width = canvas.clientWidth;
      const height = canvas.clientWidth / heightRatio;
  
      if (width > 300) {
          const parent = canvas.parentElement;
          parent.removeChild(canvas);
          
          // Recreate the canvas
          canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          this.remoteVideo.nativeElement = canvas;
          parent.append(canvas);
  
          console.log('Canvas recreated:', canvas.width, canvas.height);
      } else {
          const clientWidth = canvas.clientWidth;
          canvas.width = clientWidth;
          canvas.height = clientWidth / heightRatio;
  
          console.log('Canvas resized:', clientWidth);
      }
  
      const clientWidth = canvas.clientWidth;
      const clientHeight = clientWidth / heightRatio;
  
      console.log('Canvas dimensions:', clientWidth, clientHeight);
  
      await this.getZoomSession().renderVideo(
          this.remoteVideo.nativeElement,
          payload.userId,
          clientWidth,
          clientHeight,
          0, 0, 3
      );
  
      this.remoteVideo.nativeElement.parentElement.classList.remove('no-video');
      this.videoTrackStatusRemote$.next(true);
      
    } else if (payload.action === 'Stop') {
      await this.getZoomSession().stopRenderVideo(this.remoteVideo.nativeElement, payload.userId);
      // await this.getZoomSession().detachVideo(this.remoteVideo.nativeElement as HTMLVideoElement, payload.userId);
      await this.getZoomSession().detachVideo(payload.userId);
      this.remoteVideo.nativeElement.parentElement.classList.add('no-video');
      this.videoTrackStatusRemote$.next(false);
    }
  }

  /**
   * To get a network quality score, there must be at least two users in the session with their video on.
   * @param payload  returns a user's video network quality, as an uplink and downlink score.
   */
  private networkQualityChangeHandler(payload){
    console.log("Network Quality: ", payload);
    if (payload.type === 'downlink')
      return;

    const currUser = this.zoomClient.getCurrentUserInfo().userId;
    const targetElem = payload.userId === currUser ? this.localContainer.nativeElement : this.remoteContainer.nativeElement;
    this.displayNetworkIndicator(targetElem, payload.level);
    if (payload.userId === currUser) 
      this.networkLevel$.next(payload.level);
    else
      this.networkLevelRemote$.next(payload.level);
  }

  private displayNetworkIndicator(elem: HTMLDivElement, level: number) {
    const STEP = 0.35;
    let netElem: HTMLDivElement;
    const netElems: NodeListOf<HTMLDivElement> = elem.querySelectorAll(':scope > div.network') /*as HTMLCollectionOf<HTMLDivElement>*/;
    if (netElems.length < 1) {
      netElem = document.createElement('div');
      netElem.classList.add('network');
      elem.appendChild(netElem);
    } else
      netElem = netElems[0];

    netElem.innerHTML = level === null ? '' :
      [1, 2, 3, 4, 5]
        .map(idx => `<div id="network-bar-${idx}" ${level <= idx ? 'class="off"' : ''}></div>`)
        .join('');
  }

  private monitorVideoStatus(payload: any): void {
    if (payload.isActive) {
      console.log('Video started rendering', payload);
    } else {
      console.log('Video stopped rendering', payload);
    }
  }

}
