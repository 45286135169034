import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { mean, median } from 'mathjs/lib/esm/index.js';
import ZoomVideo, { LocalVideoTrack } from '@zoom/videosdk';

import { IsiStateService } from '@api/isi-state.service';
import { StateService } from '@api/state.service';
import { AppointmentsService } from '@api/appointments.service';
import { SessionType } from '@models/appointment';
import { FileDownloaderService } from '@api/file-downloader.service';


@Component({
  selector: 'oamw-start-session',
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.scss']
})
export class StartSessionPage implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('localVideo')
  localVideo: ElementRef<HTMLVideoElement>;
  faExclamationTriangle = faExclamationTriangle;

  tID: number;
  currTime: number;
  prevTime: number;
  speed = 0;
  bytesReceived = 0;
  oldBytes = 0;
  unit = 'Mbps';
  netSpeeds: number[] = [];
  netSpeedAvg = 0;
  networkInspector: any;
  sessionType: SessionType;
  showPermissionsError = false;
  cameraDeviceId: string;
  isPreviewStart = false;

  // local video track to show the video feed from current user's camera.
  localVideoTrack: LocalVideoTrack;


  constructor(private router: Router,
              private isiState: IsiStateService,
              private state: StateService,
              private downloader: FileDownloaderService,
              private appointmentsService: AppointmentsService) { }

  ngOnInit(): void {
    // if ((navigator.platform || navigator['userAgentData'].platform).includes('Mac'))
    //   document.getElementById('sessionStart').classList.add('mac-sessionStart');

    !this.state.hasToken() && this.router.navigate(['/']);

    this.isiState.hideIsi();
  }

  async ngAfterViewInit() {
    if (this.state.activeSessionDirty) {
      this.state.activeSessionDirty = false;
      location.reload();
    } else {
      await this.initLocalMedia(this.localVideo);
      this.download();
    }
  }

  async ngOnDestroy() {
    this.stopDownload();
    await this.localVideoTrack.stop();
  }


  async initLocalMedia(localVideoElm: ElementRef<HTMLVideoElement>) {
    /**
     * Setup video preview for zoom session. Audio preview not required. No zoom session required.
     */
    const devices =  await ZoomVideo.getDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    this.localVideoTrack = ZoomVideo.createLocalVideoTrack(videoDevices[0].deviceId);
    this.localVideoTrack.start(localVideoElm.nativeElement);
    this.isPreviewStart = true;
  }

  async startSession(type: SessionType) {
    const docElem = document.documentElement;
    !!docElem.requestFullscreen && await docElem.requestFullscreen();
    if (!!screen.orientation?.lock) {
      try {
          await screen.orientation.lock('landscape');
          console.log('Orientation locked to landscape.');
      } catch (error) {
          console.error('Failed to lock orientation:', error);
      }
    }

    this.sessionType = type;
    const prefix = this.state.user.type === 'CAPTAIN' ? 'captain' : 'participant';
    const data = { appointmentId: this.state.activeSession };
    data[prefix + 'Id'] = this.state.user.id;

    try {
      const streams = await navigator.mediaDevices.getUserMedia({ audio: true, video: type === 'video' });
      streams.getTracks().forEach(stream => stream.stop());
      console.log(streams);
    } catch (err) {
      !!screen.orientation.unlock && screen.orientation.unlock();
      console.error('ERROR: Trying to access media devices', err);
      this.showPermissionsError = true;
      return;
    }

    this.appointmentsService[prefix + 'Arrival'](data)
      .subscribe((token: string) => {
        if (token) {
          this.state.reloadRedirect = false;
          this.state.sessionType = type;
          this.state.twilioToken = token;
          this.router.navigate(['/session-active']);
        }
      });
  }

  download() {
    this.prevTime = new Date().getTime();
    this.networkInspector = this.downloader
      .download()
      .subscribe(event => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.currTime = new Date().getTime();

          this.bytesReceived = event.loaded / 1000000;
          // console.log("bytesReceied", this.bytesReceied);
          this.speed = (this.bytesReceived - this.oldBytes) /
                       ((this.currTime - this.prevTime) / 1000);

          if (!isNaN(this.speed) && this.speed > 0) {
            this.netSpeeds.push(this.speed);
            this.netSpeeds.length > 25 && this.netSpeeds.shift();
            this.netSpeedAvg = mean(this.netSpeeds);
          }

          // console.log('Curr Speed:', this.speed.toFixed(2), '\tAvg Speed:', this.netSpeedAvg.toFixed(2), this.unit);

          this.prevTime = this.currTime;
          this.oldBytes = this.bytesReceived;
        } else if (event instanceof HttpResponse)
          // console.log("Network Test done!");
          this.tID = window.setTimeout(() => this.download(), 10);
      });
  }

  stopDownload() {
    this.networkInspector.unsubscribe();
    clearTimeout(this.tID);
  }

}
