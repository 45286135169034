<div class="column">
    <section id="faq" class="bg-grey">
        <div class="container">
            <div class="row">
                <div class="col text-center">
                    <h1>Frequently Asked Questions</h1>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col ">
                    <accordion [isAnimated]="true">
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              What is Connected By Hope?
                            </button>
                          <p>
                            We understand that caring for and
                            supporting an adult loved one living with
                            schizophrenia is difficult.
                          </p>
                            <p>
                              As a participant in this program, you have
                              the opportunity to select and learn from a
                              Care Guide who has been on a similar health
                              journey already. Through this unique
                              platform, you are afforded the opportunity
                              to ask questions and hear personal accounts
                              from others like you.
                            </p>
                        </accordion-group>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              What types of questions
                              can I ask?
                            </button>
                            <p>
                              Care Guides can share their personal
                              experiences with you and try to answer any
                              questions you may have related to their
                              journey. Please note, they are not medical
                              professionals and will not be able to answer
                              any questions regarding specific medications
                              or treatments. For this information, you will
                              need to reach out to your care team.
                            </p>
                        </accordion-group>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                                Do I have to pay to participate in voce&trade; sessions?
                            </button>
                            <p>
                              No, sessions are free of charge. We
                              respectfully ask that you are mindful of the
                              Care Guide’s time and join sessions promptly
                              and prepared. If you cannot make a session,
                              please make every effort to reschedule 24
                              hours in advance.
                            </p>
                        </accordion-group>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              How do I select a topic?
                            </button>
                            <p>
                              After you have completed the registration
                              form, you will be presented with a list of
                              topics that are currently available. Simply
                              select the topic that interests you.
                            </p>
                            <p>
                              You have the ability to schedule a session on
                              one topic or schedule separate sessions for
                              each of the 3 topics.
                            </p>
                        </accordion-group>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              Do I select a Care Guide, or is
                              one assigned to me?
                            </button>
                            <p>
                              Once you’ve selected the program topic of
                              choice, you will be presented with the
                              available Care Guide to select from. It is up
                              to you to decide whom you would like to
                              chat with.
                            </p>
                        </accordion-group>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              How do I schedule a session?
                            </button>
                            <p>
                              When you select a Care Guide, you have
                              the ability to access their upcoming
                              availability. If a Care Guide is not available
                              on a certain day, that day will not be an
                              option to schedule a session. Find a date
                              and time that works with your schedule and
                              click the “Confirm” button to formally
                              reserve the session. You will immediately
                              receive an email confirming that your
                              session has been booked.
                            </p>
                        </accordion-group>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              Will I receive reminders
                              leading up to my sessions?
                            </button>
                            <p>
                              Yes. You will receive emails and text
                              message notifications leading up to each
                              session to keep you updated on the status
                              of your scheduled session. You can also add
                              your sessions to your personal calendar
                              (Google Calendar or Outlook) by going to
                              the “Upcoming Sessions” page and clicking
                              the “Add to Calendar” link. If you cannot
                              make a session, please reschedule 24 hours
                              in advance.
                            </p>
                        </accordion-group>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              Does this program work on a
                              computer and mobile phone?
                            </button>
                            <p>
                              Yes. You can use either your mobile phone
                              or a computer. For an optimal experience,
                              we recommend using Safari or Google
                              Chrome. You can choose to have a video
                              session or an audio session. Please be sure
                              you have access to a quiet environment to
                              conduct your sessions.
                            </p>
                        </accordion-group>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              Do I have to use video for
                              my sessions?
                            </button>
                            <p>
                              No. If you don’t wish to use video, you can
                              select the “audio only” option. You will still
                              sign in with your mobile phone or computer,
                              but it won’t show your video and will just
                              allow you to talk like a phone call.
                            </p>
                        </accordion-group>
                        <accordion-group >
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                              How do I become a
                              Care Guide?
                            </button>
                            <p>
                              Once you have completed 3 sessions, you
                              can email us at [email address TBD] to
                              express your interest in helping other care
                              partners.
                            </p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
        </div>
    </section>
</div>
