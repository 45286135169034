import { Component, OnInit } from '@angular/core';
import { utcToZonedTime, format } from 'date-fns-tz';
import { differenceInSeconds } from 'date-fns';
import { addBusinessDays, addMinutes } from 'date-fns';
import enUS from 'date-fns/locale/en-US';

import { Appointment } from '@models/appointment';
import { AppointmentsService } from '@api/appointments.service';
import { StateService } from '@api/state.service';
import { AvailabilitiesService } from '@api/availabilities.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TopicService } from '@api/topic.service';
import { timer } from 'rxjs';
import { AuthService } from '@api/auth.service';


@Component({
  selector: 'ke-upcoming-sessions-faculty',
  templateUrl: './upcoming-sessions-faculty.component.html',
  styleUrls: ['./upcoming-sessions-faculty.component.scss']
})
export class UpcomingSessionsFacultyPage implements OnInit {

  isDisplayed: boolean = false;
  sessionId: string;
  sessions: { id: string, date: string, time: string, zoneAbbr: string, ts: Date, topic: string, participantFirstName: string, participantLastName: string }[] = [];
  appointments: Appointment[] = [];
  topics: any[] = [];
  isDisplayNot: boolean = false;

  falseCounter: number = 0;
  deleteModalVisible = false;


  constructor(private appointmentsService: AppointmentsService,
    private state: StateService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private availabilitiesService: AvailabilitiesService,
    private topicService: TopicService) { }

  async ngOnInit(): Promise<void> {
    // if(navigator.platform.match('Mac') !== null) {
    //   document.getElementById('upcomingSessionsFaculty').classList.add('mac-upcomingFaculty');
    // }

    const dlid = this.route.snapshot.queryParamMap.get('dlid');
    if (!this.state.hasToken() && !!dlid) {
      try {
        const result = await this.authService.directLogin(dlid).toPromise();
        await this.authService.getUserDetails().toPromise();
        window.location.reload();
      } catch (error) {}
    }

    !this.state.hasToken() && this.router.navigate(['/']);
    this.sessions = [];
    this.getTopics();
    this.startTimer();
  }

  getAppointments() {
    this.appointmentsService
      .getAvailabilityByCaptain(this.state.user.id)
      .subscribe(appointments => {
        this.appointments = appointments;
        this.getAvailability();
      });
  }

  private getTopics() {
    this.topicService
      .getTopics()
      .subscribe(topics => {
        this.topics = topics;
        this.getAppointments();
      }, err => {
        console.log(err);
      });
  }

  getAvailability() {
    this.sessions = [];
    if(this.appointments.length === 0){
      this.isDisplayed = true;
      this.isDisplayNot = true;
    }
    this.appointments.forEach((av, idx) => {
      const timeZone = this.state.user.tzCanonicalName;
      const zoneAbbr = this.state.user.abbr;
      const topic = this.topics.find(t => t.id == av.topicId);
      this.availabilitiesService.getAvailabilitieById(av.availabilityId)
        .subscribe(a => {
          const date = utcToZonedTime(a.startDate, timeZone);
          this.sessions.push({
            id: av.id,
            date: format(date, 'MMM d, yyyy', { timeZone }),
            time: format(date, 'h:mm aa', { timeZone, locale: enUS }).replace('AM', 'am').replace('PM', 'pm'),
            zoneAbbr: zoneAbbr,
            ts: date,
            topic: topic.name,
            participantFirstName: av.participantFirstName,
            participantLastName: av.participantLastName
          });
          this.sessions = this.sessions.sort((a, b) => {
            return (a.ts as any) - (b.ts as any);
          });

          if (idx === this.appointments.length - 1)
          {
            this.isDisplayed = true;
            this.isDisplayNot = this.sessions.length === 0;
          }
        });
    })
  }

  showDeleteModal(id: string) {
    this.sessionId = id;
    this.deleteModalVisible = true;
  }

  isDisabled(ts: Date) {
    const currTime = utcToZonedTime(new Date(), this.state.user.tzCanonicalName);
    const diff = differenceInSeconds(ts, currTime);
    return diff > 5 * 60;
  }

  startTimer() {
    timer(0, 1000).subscribe(ellapsedCycles => {
     this.falseCounter += 1;
    });
  }

  deleteSession() {
    console.log('Deleting session #' + this.sessionId);
    this.appointmentsService
      .deleteAppointment(this.sessionId)
      .subscribe(success => {
        this.getAppointments();
        this.sessions = this.sessions.filter(s => s.id !== this.sessionId);
        this.sessionId = undefined;
        this.deleteModalVisible = false;
      });

  }

  toggleAddCalendar(event: MouseEvent) {
    const elem = event.target as HTMLElement;
    const card = elem.closest('.card-session');
    card.classList.toggle('collapsed');
    card.nextElementSibling.classList.toggle('collapse');
  }

  addToGoogle(session: any) {
    const dt = new Date(session.date + ' ' + session.time);
    const from = format(dt, 'yyyyMMdd HHmmss');
    const to = format(new Date(dt.getTime() + 30 * 60000), 'yyyyMMdd HHmmss');
    return 'https://calendar.google.com/calendar/r/eventedit?text=OAMYWAY%20Live%20Session-' + session.topic + '&dates='
      + from.replace(' ', 'T') + '%2F' + to.replace(' ', 'T');
  }

  addToOutlook(session: any, type: string) {
    const dt = new Date(session.date + ' ' + session.time);
    const from = format(dt, 'yyyy-MM-dd HH:mm:ss');
    const to = format(new Date(dt.getTime() + 30 * 60000), 'yyyy-MM-dd HH:mm:ss');
    if(type == 'live'){
    return 'https://outlook.live.com/calendar/0/deeplink/compose?'
      + 'path=%2Fcalendar%2Faction%2Fcompose&rru=addevent'
      + '&startdt=' + from.replace(' ', 'T')
      + '&enddt=' + to.replace(' ', 'T')
      + '&subject=OAMYWAY%20Live!%20Session-' + session.topic
      + '&allday=false'
      + from.replace(' ', 'T') + '%2F' + to.replace(' ', 'T');
    }
    else if(type == '365'){
      return 'https://outlook.office365.com/calendar/0/deeplink/compose?'
      + 'path=%2Fcalendar%2Faction%2Fcompose&rru=addevent'
      + '&startdt=' + from.replace(' ', 'T')
      + '&enddt=' + to.replace(' ', 'T')
      + '&subject=OAMYWAY%20Live!%20Session-' + session.topic
      + '&allday=false'
      + from.replace(' ', 'T') + '%2F' + to.replace(' ', 'T');
    }
  }

  joinSession(id: string, st: Date) {
    this.state.activeSession = id;
    this.state.sessionStartTime = format(st, 'yyyy-MM-dd HH:mm:ss').replace(' ', 'T');
    this.router.navigate(['/start-session']);
  }

  ///

  // private calculateSessions() {
  //   const timeZone = 'America/New_York'; // should get from profile info
  //   const day = new Date().getDate().toString().padStart(2, '0');
  //   const estDt = utcToZonedTime(`2020-06-${day}T18:00:00Z`, timeZone);

  //   [0, 1, 2].forEach(id => {
  //     const currDt = addMinutes(addBusinessDays(estDt, id), id * 30);
  //     this.sessions.push({
  //       id,
  //       date: format(currDt, 'MMM d, yyyy', { timeZone }),
  //       time: format(currDt, 'h:mm a z', { timeZone, locale: enUS })
  //     });
  //   });

  // }

}
