import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '@api/state.service';


@Component({
  selector: 'oamw-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqPage implements OnInit {

  constructor(private state: StateService,
    private router: Router,) { }

  ngOnInit(): void {
    if(navigator.platform.match('Mac') !== null) {
      document.getElementById('faq').classList.add('mac-faq');
    }
    !this.state.hasToken() && this.router.navigate(['/']);

  }

}
