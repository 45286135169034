import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'oamw-confirm-isi',
  templateUrl: './confirm-isi.component.html',
  styleUrls: ['./confirm-isi.component.scss']
})
export class ConfirmIsiPage implements OnInit {

  isTermsChecked = false;


  constructor(private router: Router) { }

  ngOnInit(): void {
    // if(navigator.platform.match('Mac') !== null) {
    //   document.getElementById('confirmIsi').classList.add('mac-isi');
    // }
  }

  confirmIsiRead() {
    this.router.navigate(['start-session']);
  }

}
