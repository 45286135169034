<!--Sign Up Page Menu-->
<div class="column left-signup" *ngIf="!isLandingPage && !isLoginPage && !isLoggedIn && !isTermsPage && !isUnsubscribe && !isForgotPwdPage && !isNewPwdPage">
  <div>
    <div class="container-logo">
      <div class="col text-center">
        <img [routerLink]="['/']" src="assets/images/voce/logo.png" class="logo-left">
      </div>
    </div>
    <section id="welcome">
      <h1 class="image-header">
        An easy way for you to connect with other care partners of adults living with schizophrenia
      </h1>
    </section>
  </div>
  <img *ngIf="route.url === '/signup'" src="assets/images/voce/SignUp-IMG.png" class="img-fluid jumbo-ke">
  <img *ngIf="route.url === '/select-topic'" src="assets/images/voce/SelectTopic-IMG.png" class="img-fluid jumbo-ke">
  <img *ngIf="route.url === '/select-ambassador'" src="assets/images/voce/SelectCareGuide-IMG.png" class="img-fluid jumbo-ke">
  <img *ngIf="route.url === '/select-session'" src="assets/images/voce/SelectDate-IMG.png" class="img-fluid jumbo-ke">
  <img *ngIf="route.url === '/booked'" src="assets/images/voce/Booked-IMG.png" class="img-fluid jumbo-ke">
</div>

<!--Logged In Desktop Menu-->
<div class="mobile-hide desktop-menu"
  *ngIf="!isLandingPage && !isLoginPage && isLoggedIn && route.url != '/confirm-isi' && route.url != '/start-session' && route.url != '/session-active' && route.url != '/rating' && !route.url.startsWith('/new-password') && !route.url.startsWith('/forgot-password') && !route.url.startsWith('/unsubscribe')">
  <div class="column left">
    <div class="container-logo">
      <div class="text-center mb-3">
        <a href="/"><img src="assets/images/voce/logo.png" class="logo-left"></a>
      </div>
    </div>
    <section id="menu">
      <div>
        <div class="menu">
          <ul *ngIf="user.type === 'PARTICIPANT'">
            <li routerLinkActive="active-link">
              <a [routerLink]="['/upcoming-sessions']" (click)="isMenuOpen = false">Upcoming Sessions</a>
            </li>
            <li routerLinkActive="active-link" [ngClass]="route.url === '/select-ambassador' || route.url === '/select-session' || route.url === '/booked' ? 'active-link' : ''">
              <a [routerLink]="['/select-topic']" (click)="isMenuOpen = false">Schedule New Session</a>
            </li>
            <li routerLinkActive="active-link">
              <a [routerLink]="['/faq']" (click)="isMenuOpen = false">FAQs</a>
            </li>
            <li routerLinkActive="active-link">
              <a [routerLink]="['/content-library']" (click)="isMenuOpen = false">Content Library</a>
            </li>
          </ul>

          <ul *ngIf="user.type === 'CAPTAIN'">
            <li routerLinkActive="active-link">
              <a [routerLink]="['/upcoming-sessions-faculty']" (click)="isMenuOpen = false">Upcoming Sessions</a>
            </li>
            <li routerLinkActive="active-link">
              <a [routerLink]="['/set-availability']" (click)="isMenuOpen = false">Set Availability</a>
            </li>
            <li routerLinkActive="active-link">
              <a [routerLink]="['/faq']" (click)="isMenuOpen = false">FAQs</a>
            </li>
            <li routerLinkActive="active-link">
              <a [routerLink]="['/content-library']" (click)="isMenuOpen = false">Content Library</a>
            </li>
<!--            <li routerLinkActive="active-link">-->
<!--            <a [routerLink]="['/report-drug-reaction']" (click)="isMenuOpen = false">Report Adverse Drug Reaction</a>-->
<!--          </li>-->
          </ul>
        </div>
      </div>
    </section>
    <img
      *ngIf="route.url === '/select-topic' || route.url === '/select-ambassador' || route.url === '/select-session' || route.url === '/booked' || route.url === '/set-availability'"
      src="assets/images/voce/SchedulePage-IMG.png" class="img-fluid jumbo-ke">
    <img *ngIf="route.url === '/upcoming-sessions' || route.url === '/upcoming-sessions-faculty'"
      src="assets/images/voce/UpcomingSession-IMG.png" class="img-fluid jumbo-ke">
    <img *ngIf="route.url === '/faq'" src="assets/images/voce/FAQpage-IMG.png" class="img-fluid jumbo-ke">
    <img *ngIf="route.url === '/content-library' || route.url === '/report-drug-reaction'" src="assets/images/voce/ContentLibraryPage-IMG.png"
      class="img-fluid jumbo-ke">
  </div>
</div>

<div class="content"
  [ngClass]="{ 'fullWidth': isFullWidth, 'bgPurple': route.url === '/confirm-isi' || route.url === '/start-session' || route.url === '/session-active',
  'grad-fix': route.url === '/report-drug-reaction' || route.url === '/upcoming-sessions-faculty' || route.url === '/set-availability' || route.url === '/faq' || route.url === '/content-library' }">
  <!--Logged in Mobile Menu-->
  <div
    *ngIf="!isLandingPage && !isLoginPage && isLoggedIn && !isTermsPage && route.url != '/start-session' && route.url != '/session-active' && route.url != '/rating'">
    <div class="header-height">
      <div>
        <div class="desktop-hide">
          <div class="flex flex-row">
            <div class="col text-center width-70 logged-in-menu">
              <a href="/"><img src="assets/images/voce/logo.png" class="logo-login"></a>
            </div>
            <div class="menu-icon-mobile width-30">
              <img src="assets/images/HamburgerMenu.png" (click)="isMenuOpen = true">
            </div>
          </div>
        </div>
        <div class="col pt-3 mobile-hide">
          <span *ngIf="route.url === '/session-active'">
            <fa-icon [icon]="faCheckCircle" size="sm" class="text-success" *ngIf="isConnected"></fa-icon>
            <fa-icon [icon]="faBan" size="sm" class="text-error" *ngIf="!isConnected"></fa-icon>
          </span>
          <img src="assets/images/user-avatar.jpg" class="user-image" />
          <span class="header-name">{{ user.firstName}}</span>
          <!--           <div class="dropdown-menu" *dropdownMenu>-->
          <!--              <a class="dropdown-item" (click)="logout()">Logout</a>-->
          <!--          </div>-->

          <button (click)="logout()" class="pl-1 pr-0 btn btn-text text-dark-grey float-right" type="button">
            Sign Out
          </button>
        </div>
      </div>
    </div>
    <div class="menu-tray" *ngIf="isMenuOpen"
      [ngClass]="route.url === '/select-topic' || route.url === '/select-ambassador' || route.url === '/select-session' || route.url === '/booked' || route.url === '/set-availability' ? 'schedule-bg' : route.url === '/upcoming-sessions' || route.url === '/upcoming-sessions-faculty' ? 'upcoming-bg': route.url === '/faq'? 'faq-bg' : route.url === '/content-library' ? 'content-bg' : 'grad1'">
      <button type="button" class="menu-close close" aria-label="Close" (click)="isMenuOpen = false">
        <span aria-hidden="true">
          <img src="assets/images/XBTN-d.png" width="20">
        </span>
      </button>
      <ul *ngIf="user.type === 'PARTICIPANT'">
        <li routerLinkActive="active-link">
          <a [routerLink]="['/upcoming-sessions']" (click)="isMenuOpen = false">Upcoming Sessions</a>
        </li>
        <li routerLinkActive="active-link" [ngClass]="route.url === '/select-ambassador' || route.url === '/select-session' || route.url === '/booked' ? 'active-link' : ''">
          <a [routerLink]="['/select-topic']" (click)="isMenuOpen = false">Schedule New Session</a>
        </li>
        <li routerLinkActive="active-link">
          <a [routerLink]="['/faq']" (click)="isMenuOpen = false">FAQs</a>
        </li>
        <li routerLinkActive="active-link">
          <a [routerLink]="['/content-library']" (click)="isMenuOpen = false">Content Library</a>
        </li>
        <li class="mt-5">
          <a (click)="logout()" class="purple">Sign Out</a>
        </li>
      </ul>

      <ul *ngIf="user.type === 'CAPTAIN'">
        <li routerLinkActive="active-link">
          <a [routerLink]="['/upcoming-sessions-faculty']" (click)="isMenuOpen = false">Upcoming Sessions</a>
        </li>
        <li routerLinkActive="active-link">
          <a [routerLink]="['/set-availability']" (click)="isMenuOpen = false">Set Availability</a>
        </li>
        <li routerLinkActive="active-link">
          <a [routerLink]="['/faq']" (click)="isMenuOpen = false">FAQs</a>
        </li>
        <li routerLinkActive="active-link">
          <a [routerLink]="['/content-library']" (click)="isMenuOpen = false">Content Library</a>
        </li>
<!--        <li routerLinkActive="active-link">-->
<!--          <a [routerLink]="['/report-drug-reaction']" (click)="isMenuOpen = false">Report Adverse Drug Reaction</a>-->
<!--        </li>-->

        <li class="mt-5">
          <a (click)="logout()" class="purple">Sign Out</a>
        </li>
      </ul>
    </div>

  </div>

  <!--Not Logged in Mobile Header-->
  <div
    *ngIf="!isLandingPage && !isLoginPage && !isUnsubscribe && !isForgotPwdPage && !isNewPwdPage && !isLoggedIn && !isTermsPage && route.url != '/start-session' && route.url != '/session-active' && route.url != '/rating'">
    <div class="header-height mobile-header-signup" [ngClass]="!isLoggedIn && route.url === '/signup' ? 'signup-bg': route.url === '/select-topic' ? 'topic-bg': route.url === '/select-ambassador' ? 'ambassador-bg': route.url === '/select-session' ? 'session-bg': route.url === '/booked' ? 'booked-bg': ''">
      <div class="desktop-hide">
        <div class="col text-center">
          <a href="/"><img src="assets/images/voce/logo.png" class="logo-login not-logged"></a>
        </div>
        <h1 class="image-header" *ngIf="!isLoggedIn">
          An easy way for you to connect with other care partners of adults living with schizophrenia
        </h1>
      </div>
    </div>
  </div>

  <!--Logged in Video Session Menu Mobile and Desktop-->
  <div class="session-container"
    *ngIf="!isLandingPage && !isLoginPage && isLoggedIn && (isTermsPage || route.url === '/start-session'  || route.url === '/session-active'  || route.url === '/rating')">
    <div class="grad1">
      <div class="menu-tray menu-tray-shadow" *ngIf="isMenuOpen"
        [ngClass]="route.url === '/select-topic' || route.url === '/select-ambassador' || route.url === '/select-session' || route.url === '/booked' || route.url === '/set-availability' ? 'schedule-bg' : route.url === '/upcoming-sessions' || route.url === '/upcoming-sessions-faculty' ? 'upcoming-bg': route.url === '/faq'? 'faq-bg' : route.url === '/content-library' ? 'content-bg' : 'grad1'">
        <button type="button" class="menu-close close" aria-label="Close" (click)="isMenuOpen = false">
          <span aria-hidden="true">
            <img src="assets/images/XBTN-d.png" width="20">
          </span>
        </button>
        <ul *ngIf="user.type === 'PARTICIPANT' && route.url !== '/session-active'">
          <li routerLinkActive="active-link">
            <a [routerLink]="['/upcoming-sessions']" (click)="isMenuOpen = false">Upcoming Sessions</a>
          </li>
          <li routerLinkActive="active-link" [ngClass]="route.url === '/select-ambassador' || route.url === '/select-session' || route.url === '/booked' ? 'active-link' : ''">
            <a [routerLink]="['/select-topic']" (click)="isMenuOpen = false">Schedule New Session</a>
          </li>
          <li routerLinkActive="active-link">
            <a [routerLink]="['/faq']" (click)="isMenuOpen = false">FAQs</a>
          </li>
          <li routerLinkActive="active-link">
            <a [routerLink]="['/content-library']" (click)="isMenuOpen = false">Content Library</a>
          </li>
        </ul>

        <ul *ngIf="user.type === 'CAPTAIN' && route.url !== '/session-active'">
          <li routerLinkActive="active-link">
            <a [routerLink]="['/upcoming-sessions-faculty']" (click)="isMenuOpen = false">Upcoming Sessions</a>
          </li>
          <li routerLinkActive="active-link">
            <a [routerLink]="['/set-availability']" (click)="isMenuOpen = false">Set Availability</a>
          </li>
          <li routerLinkActive="active-link">
            <a [routerLink]="['/faq']" (click)="isMenuOpen = false">FAQs</a>
          </li>
          <li routerLinkActive="active-link">
            <a [routerLink]="['/content-library']" (click)="isMenuOpen = false">Content Library</a>
          </li>
<!--          <li routerLinkActive="active-link">-->
<!--            <a [routerLink]="['/report-drug-reaction']" (click)="isMenuOpen = false">Report Adverse Drug Reaction</a>-->
<!--          </li>-->
        </ul>
        <ul *ngIf="route.url === '/session-active'">
          <li *ngIf="route.url === '/session-active'">
            <a (click)="navigateByReload(user.type === 'CAPTAIN' ? '/upcoming-sessions-faculty' : '/upcoming-sessions')" class="cursor-pointer">Upcoming Sessions</a>
          </li>
          <li routerLinkActive="active-link">
            <a (click)="navigateByReload('/set-availability')" (click)="isMenuOpen = false" class="cursor-pointer">Set Availability</a>
          </li>
          <li routerLinkActive="active-link">
            <a (click)="navigateByReload('/faq')" (click)="isMenuOpen = false">FAQs</a>
          </li>
          <li routerLinkActive="active-link">
            <a (click)="navigateByReload('/content-library')" (click)="isMenuOpen = false" class="cursor-pointer">Content Library</a>
          </li>
          <li class="mt-5" *ngIf="route.url === '/session-active'">
            <a (click)="logout()" class="purple cursor-pointer">Sign Out</a>
          </li>
        </ul>
      </div>
      <div class="container-fluid position-abs" [ngClass]="route.url === '/confirm-isi' || route.url === '/start-session' || route.url === '/session-active' ? 'logo-fix' : '' ">
        <div class="row session-logo-container">
          <div class="col pl-15 pr-0 icon-container">
            <img class="session-menu-icon" src="assets/images/HamburgerMenu.png" (click)="isMenuOpen = true">
          </div>
          <div class="container-logo">
            <div class="col text-center">
              <img src="assets/images/voce/logo.png" class="logo-video">
            </div>
            <div *ngIf="route.url === '/confirm-isi' || route.url === '/start-session' || route.url === '/session-active'" class="desktop-hide username">{{ user.firstName}}</div>
          </div>
          <div class="col text-right pl-0 pr-15 session-font">
<!--            <span *ngIf="route.url === '/session-active'">-->
<!--              <fa-icon [icon]="faCheckCircle" size="sm" class="text-success" *ngIf="isConnected"></fa-icon>-->
<!--              <fa-icon [icon]="faBan" size="sm" class="text-error" *ngIf="!isConnected"></fa-icon>-->
<!--            </span>-->
            {{ user.firstName}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Supported Device Check-->
  <!-- <div id="browser-error" class="d-flex align-content-center align-items-center h-100 px-3"
    *ngIf="!isValidBrowser && (route.url === '/upcoming-sessions-faculty' || route.url === '/upcoming-sessions')">
    <div class="alert alert-danger border-1">
      <h4 class="alert-icon">
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
      </h4>
      <p class="mb-1">
        Your browser is not supported. To continue, please use a
        newer browser such as:
      </p>
      <ul class="pl-3">
        <ng-container *ngIf="!isMacDevice">
          <li>Chrome v56+</li>
          <li>Firefox v44+</li>
          <li *ngIf="browserInfo.os !== 'Linux'">Edge v79+</li>
          <li>Safari v11+</li>
        </ng-container>
        <ng-container *ngIf="isMacDevice">
          <li>Safari v11+</li>
        </ng-container>
      </ul>
    </div>
  </div> -->

  <!--Logout Modal-->
  <div id="confirm-popup" *ngIf="endModalVisible">
    <div class="confirm-body mt-5">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <p class="py-5">Are you sure you want to logout. You can rejoin later.</p>
          </div>
        </div>
      </div>

      <div class="confirm-tray">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary bg-light text-purple" (click)="endModalVisible = false">No
            </button>
          </div>
          <div class="col">
            <button type="button" class="btn btn-primary bg-purple" (click)="disconnectRoomAndLogout()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <router-outlet >
  </router-outlet>
  <notifier-container></notifier-container>
</div>
