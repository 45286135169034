import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { TopicService } from '@api/topic.service';
import { StateService } from '@api/state.service';
import { Topic } from '@models/topic';
import { AuthService } from '@api/auth.service';


@Component({
  selector: 'oamw-select-topic',
  templateUrl: './select-topic.component.html',
  styleUrls: ['./select-topic.component.scss']
})
export class SelectTopicPage implements OnInit{

  topicId: number;
  selectedTopicID: string;
  topics: Topic[];
  isLoggedIn = true;


  constructor(private router: Router,
              private topicService: TopicService,
              private state: StateService,
              private location: Location,
              private auth: AuthService) { }

  ngOnInit(): void {
    // if(navigator.platform.match('Mac') !== null) {
    //   document.getElementById('topicSelect').classList.add('mac-topicSelect');
    // }
    !this.state.hasToken() && this.router.navigate(['/']);
    this.auth.isLoggedIn.subscribe( status => this.isLoggedIn = status );

    this.getTopics();
  }

  private getTopics() {
    this.topicService
        .getTopics()
        .subscribe(topics => {
          this.topics = topics;
        },err => {
          console.log(err);
        });
  }

  selectTopic(topicID) {
    this.selectedTopicID = topicID;
  }

  gotoFaculty() {
    let topicID = this.selectedTopicID;
    this.state.selectedTopicByParticipant = topicID;
    this.router.navigate(['/select-ambassador']);
  }

  gotoFacultyLoggedIn(topicID) {
    this.topicId = topicID;
    this.state.selectedTopicByParticipant = topicID;
    this.router.navigate(['/select-ambassador']);
  }

  gotoUpcoming() {
    this.router.navigate(['/upcoming-sessions']);
    this.auth.updateLoginFlag();
  }

  goBack() {
    this.location.back();
  }

}
