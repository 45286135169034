<div class="pt-10p" id="activeSession">

  <details *ngIf="userType === 'CAPTAIN' && !!adrResults && adrResults.drugMentioned && adrResults.adrs?.length > 0">
    <summary>ADR Detected</summary>
    <ul>
      <li *ngFor="let adr of adrResults.adrs">
        <code>{{adr.timestamp | date: 'MM/dd/yyyy HH:mm:ss'}}</code><br>
        <p> {{adr.reason}} </p>
      </li>
    </ul>
  </details>

  <div id="network-warning" [hidden]="(!networkLevel || networkLevel > 2) && (this.sessionDurationLeft > 0)">
    <div [hidden]="!networkLevel || networkLevel > 2">
      Your network strength is too low which may cause connection issues
      with your video chat. Consider moving to a better connection or disabling your video.
    </div>
    <div [hidden]="this.sessionDurationLeft > 0">
      Session will automatically end in {{timerDisplay}}
    </div>
  </div>

  <div id="video-container">

    <div id="remoteVideo" class="no-video no-indicators" #remoteVideoContainer>
      <div id="indicators">
        <img src="assets/images/Mic-{{isAudioMutedRemote ? 'OFF' : 'ON'}}.png" />
        <img src="assets/images/Video-{{isVideoMutedRemote ? 'OFF' : 'ON'}}.png" />
      </div>
      <span class="timer" *ngIf="this.sessionDurationLeft > 0">{{timerDisplay}}</span>

      <canvas #remoteVideo></canvas>
      <div [hidden]="!sessionStarted" id="localVideo" class="no-video" [class.leveluplocal]="!sessionStarted" #localVideoContainer>
        <video #localVideo></video>
      </div>
    </div>
  </div>

  <section *ngIf="!sessionStarted && !isBothPartyArrived">
    <div class="row mr-0 mb-0">
      <div class="col">
        <div class="container text-center" *ngIf="userType === 'PARTICIPANT'">
          <p class="session-description">Your session will begin as soon as the {{captainLabel}} arrives…</p>
          <br>
        </div>
        <div class="container text-center" *ngIf="userType !== 'PARTICIPANT'">
          <p class="session-description">Your session will begin as soon as the {{participantLabel}} arrives…</p>
          <button (click)="remindCandidate()" class="btn btn-text btn-remind text-purple mt-2 mb-4"
            *ngIf="userType!=='!participant'">Click here to send a reminder</button>
        </div>
      </div>
    </div>
  </section>
  <!-- <div class="text-danger text-center hand-device-only" *ngIf="!isScreenLockedSupported">
    Please rotate you device for a better experience.
  </div> -->
  <section class="player-controls" *ngIf="isBothPartyArrived || sessionStarted" (window:resize)="onResize($event)">
      <div class="container control-panel">
        <div class="control-btn-container">
          <div class="flex align-items-center video-btn">
            <span class="text-center">
              <img class="cursor-pointer" (click)="toggleVideo()" [src]="isVideoMuted ? 'assets/images/Video-OFF.png' : 'assets/images/Video-ON.png'">
            </span>
          </div>

          <div class="flex align-items-center end-btn">
            <span class="text-center">
              <img class="cursor-pointer" (click)="showEndModal()" src="assets/images/End-Call.png">
            </span>
          </div>

          <div class="flex align-items-center audio-btn">
            <span class="text-center pointer-event">
              <img class="cursor-pointer" (click)="toggleAudio()" [src]="isAudioMuted ? 'assets/images/Mic-OFF.png' : 'assets/images/Mic-ON.png'">
            </span>
          </div>
        </div>
      </div>
      <div id="confirm-popup" *ngIf="endModalVisible">
        <div class="confirm-body">
          <div>
            <div>
              <div class="col text-center">
                <p>Are you sure you want to end your Session?</p>
              </div>
            </div>
          </div>

          <div class="confirm-tray">
            <div class="row">
              <div class="col width-30 text-right m-width-100 m-text-center">
                <button type="button" class="btn btn-primary outline-btn bg-light" (click)="endModalVisible = false">No</button>
              </div>
              <div class="col m-text-center">
                <button type="button" class="btn btn-primary" (click)="disconnectRoom(true)">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</div>
