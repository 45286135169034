import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest,
  HttpHandler, HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { StateService } from '@api/state.service';
import { environment } from 'src/environments/environment';
import { ZoomService } from '@api/zoom.service';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private state: StateService, private router: Router, private zoomService: ZoomService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modReq = req;
    let newHeaders = req.headers.append('Tenant-ID', environment.tenantId)
                                .append('Accept', '*/*');
    modReq = req.clone({ headers: newHeaders });
    if (this.state.hasToken()) {
      newHeaders = newHeaders.append('Authorization', 'Bearer ' + this.state.token);
      modReq = modReq.clone({ headers: newHeaders });
    }

    return next.handle(modReq)
               .pipe(tap(
                 () => {},
                 async (err: any) => {
                   if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                      console.log(err);
                      return;
                    }

                    await this.zoomService.disposeLocalMedia();
                    this.zoomService.leaveSession(false);
                    const redirectPath = '/';

                    this.state.clearToken();
                    this.state.clearAll();
                    this.router.navigate([redirectPath]);
                   }
                 }
               ));
  }
}
