import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountsService } from '@api/accounts.service';

@Component({
  selector: 'omw-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordPage implements OnInit {

  form: FormGroup;

  submitted = false;
  success = false;


  constructor(private fb: FormBuilder, private router: Router, private accountService: AccountsService) { }

  ngOnInit(): void {
    // if(navigator.platform.match('Mac') !== null) {
    //   document.getElementById('forgot-pwd').classList.add('mac-pwd');
    // }

    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return !control.valid && (control.touched || (control.untouched && this.submitted));
  }

  doForgotPassword() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    const formData = {email: this.form.value.email};
    this.accountService.forgotPassword(formData).subscribe(success => {
      this.success = success;
    });

  }

  protected readonly navigator = navigator;
}
