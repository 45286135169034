<div class="column" [ngClass]="isLoggedIn ? '' : 'right-signup'">
    <section id="topicSelect" class="bg-grey topicSelect">
      <div>
        <div class="text-center" *ngIf="!isLoggedIn">
            <h1 class="purple">Select a Topic</h1>
        </div>
        <div *ngIf="!isLoggedIn">
          <div class="col text-center desktop-hide">
            <img src="assets/images/process-step-two-m.png" class="img-fluid step-image-padding">
          </div>
          <div class="col text-center mobile-hide">
            <img src="assets/images/process-step-two.png" class="img-progress">
          </div>
        </div>
        <div *ngIf="isLoggedIn">
          <div class="text-center loggedIn-header desktop-hide" *ngIf="isLoggedIn">
            <h1 class="">Select a Topic</h1>
          </div>
          <div class="col text-center desktop-hide">
            <img src="assets/images/process-step-one-m.png" class="img-fluid step-image-padding">
          </div>
          <div class="col text-center mobile-hide">
            <img src="assets/images/process-step-one.png" class="img-progress">
          </div>
        </div>
        <div class="text-center loggedIn-header mobile-hide" *ngIf="isLoggedIn">
          <h1 class="">Select a Topic</h1>
          <h2 class="description">
            Select the topic you’d most like to discuss with your Care Guide.
          </h2>
        </div>

        <div *ngIf="!isLoggedIn">
          <div class="select-list">
            <ul class="list-group list-topic">
              <li class="list-group-item topic-item bg-purple text-center cursor-p" [ngClass]="selectedTopicID === t.id ? 'selected' : ''"
                  (click)="selectTopic(t.id)" *ngFor="let t of topics">
                {{t.name}}
              </li>
            </ul>
          </div>
          <div class="row flex-column">
            <div class="col text-center">
              <button type="button" class="btn btn-primary" [disabled]="selectedTopicID === undefined" (click)="gotoFaculty()">Next</button>
            </div>
            <div class="col text-center">
              <button type="button" class="btn btn-primary outline-btn" (click)="gotoUpcoming()">Schedule a Session Later</button>
            </div>
          </div>
        </div>
        <div *ngIf="isLoggedIn">
          <div class="select-list">
            <ul class="list-group list-topic">
              <li class="list-group-item topic-item bg-purple text-center cursor-p" [ngClass]="selectedTopicID === t.id ? 'selected' : ''"
                  (click)="selectTopic(t.id)" *ngFor="let t of topics">
                {{t.name}}
              </li>
            </ul>
          </div>
          <div class="col text-center">
            <button type="button" class="btn btn-primary btn-loggedIn" [disabled]="selectedTopicID === undefined" (click)="gotoFaculty()">Next</button>
          </div>
        </div>
      </div>
<!--      <span (click)="goBack()" class="pointer left-arrow mobile-hide" *ngIf="!isLoggedIn">-->
<!--          <img src="assets/images/backBTN.png" class="back-arrow">-->
<!--      </span>-->
    </section>
</div>
